<template>
<div class="modal fade " id="ViewNotesModal" tabindex="-1" role="dialog" aria-labelledby="ViewNotesModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Notes</h1>
                <button v-if="!isEditModalActive" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div v-if="!isEditModalActive">
                    <div class="row d-flex justify-content-center">

                        <div class="col-12 col-md-12" v-for="(appointmentNote,index) in appointmentNotes" :key="index">
                            <div class="d-flex ">
                                <div class="" style="flex-shrink: 0">
                                    <img class="rounded-avatar" :src="appointmentNote.user.avatar" alt="">
                                </div>

                                <div class="ml-1">
                                    <h5 class="list-title mb-0 font-weight-bold">{{appointmentNote.user.first_name+ " "+appointmentNote.user.last_name}} <small class="text-italic font-weight-normal">as {{appointmentNote.user_type}}</small></h5>
                                    <div class="py-1">
                                        <small class="text-italic font-weight-normal d-block">Created : <span class="text-dark font-weight-bold">{{appointmentNote.created_at}}</span></small>
                                        <small v-if="appointmentNote.created_at !== appointmentNote.updated_at" class="text-italic font-weight-normal d-block">Last modified : <span class="text-dark font-weight-bold">{{appointmentNote.updated_at}}</span></small>

                                    </div>
                                    <div class="d-flex">
                                        <span class=" font-weight-bold mb-1 badge badge-circle-primary font-small-1 ">{{appointmentNote.type}} </span>
                                        <div class="d-flex align-items-center ml-1">
                                            <small class=" font-weight-bold mb-1 font-small-1 text-warning"> {{appointmentNote.created_at !== appointmentNote.updated_at ? ' Updated previously ' :''}} </small>
                                            <i v-if="isEditAble(appointmentNote.user,appointmentNote.user_type)" class="text-danger bx bx-edit mb-1 ml-1 cursor-pointer" title="Update Note" style="font-size: 15px;" @click="openNoteEditModal(appointmentNote)"></i>

                                            <i v-if="isEditAble(appointmentNote.user,appointmentNote.user_type)" class="text-danger bx bxs-message-alt-x mb-1 ml-1 cursor-pointer" style="font-size: 16px;" title="Delete Note" @click="openNoteNoteDeleteAlertModal(appointmentNote)"></i>
                                        </div>

                                    </div>

                                    <p class="font-small-3">{{appointmentNote.description}}</p>
                                </div>
                            </div>
                            <hr>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button v-if="!isEditModalActive" type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>
            </div>
        </div>
        <div class="" data-toggle="modal" data-target="#appointmentEditNoteModal"></div>
        <div class="" data-toggle="modal" data-target="#AppointmentNoteDeleteAlertModal"></div>
        <AppointmentEditNoteModal @updateEditModalActiveStatus="updateEditModalActiveStatus(status)" :note="{...selectedEditAbleNote}" />
        <AppointmentNoteDeleteAlertModal @confirmModelDeletion="confirmModelDeletion" />
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from "vuex";
import AppointmentEditNoteModal from "./AppointmentEditNoteModal.vue";
import AppointmentNoteDeleteAlertModal from "./AppointmentNoteDeleteAlertModal.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    name: "AdminNoteView",
    mixins: [ShowToastMessage, Loader],
    props: [],
    components: {
        AppointmentEditNoteModal,
        AppointmentNoteDeleteAlertModal
    },

    data() {
        return {
            isEditModalActive: false,
            selectedEditAbleNote: {},
        };
    },
    computed: {
        ...mapGetters({
            appointmentNotes: "appAppointments/appointmentNotes",
            userType: "appUsers/userType",
        }),
    },
    watch: {},
    methods: {
        ...mapActions({
            deleteAppointmentNoteOnList: 'appAppointments/deleteAppointmentNoteOnList',
        }),

        isEditAble(user, userType) {
            console.log(user, "user ", userType, "  userType");
            if ((user.id === this.$store.getters["appAuthLogin/authUser"].id) && this.userType.find((type) => type.name === userType)) {
                return true;
            }
            return false;
        },
        openNoteEditModal(note) {
            this.selectedEditAbleNote = {};
            this.selectedEditAbleNote = note;
            this.isEditModalActive = true;
            document.querySelector('[data-target="#appointmentEditNoteModal"]').click();

        },
        updateEditModalActiveStatus() {
            this.isEditModalActive = false
        },
        openNoteNoteDeleteAlertModal(note) {
            this.selectedEditAbleNote = {};
            this.selectedEditAbleNote = note;
            this.isEditModalActive = true;
            document.querySelector('[data-target="#AppointmentNoteDeleteAlertModal"]').click();
        },
        async confirmModelDeletion(confirmModelDeletion) {
            if (confirmModelDeletion) {
                await this.deleteSingleAppointmentNoteOnLIst();
            }
            this.updateEditModalActiveStatus();

        },
        async deleteSingleAppointmentNoteOnLIst() {
            this.loader(true);
            const response = await this.deleteAppointmentNoteOnList(this.selectedEditAbleNote.id);
            this.loader(false);
            if (response.status === 200 || response.status === 204) {
                const toastObj = {
                    message: "Appointment note deleted successfully",
                    type: 'success'
                };
                this.showToastMessage(toastObj);

                return;

            }
            this.showToastMessage(response);

        },
    },

};
</script>

<style scoped>
.rounded-avatar {
    overflow: hidden;
    width: 60px;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #7698d9 !important;
    border-bottom: none !important;
    border-left: none !important;
    /* border-top: none  !important; */
}
</style>
