<script>
import ConfirmDialogue from "@/components/backEnd/modal/ConfirmDialogue.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {mapActions} from "vuex";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "PaymentCollectionModal",
  mixins: [ShowToastMessage, Loader, GlobalMixin],
  props: ['appointment', 'appointmentParams'],
  components: {ConfirmDialogue, QuillEditor},
  data() {
    return {
      paymentCollection: {},
      paymentCollectionDetails: [],
      stage1: 'I wanted to bring to your attention that there is an outstanding balance on your account.' +
          'we kindly request that you settle this payment at your earliest convenience.',
      stage2: '',
      stage3: '',
      details: {
        stage1: {
          message: '',
          isSend: false,
        },
        stage2: {
          message: '',
          isSend: false,
        },
        stage3: {
          message: '',
          isSend: false,
        }
      },
    }
  },
  computed: {
    stageTemplate() {
      return this.$store.getters["appSettings/settingDefaultAppointmentPaymentCollectionStageTemplate"].value;
    }
  },
  watch: {
    appointment: {
      handler(currentVale) {
        this.paymentCollection = currentVale?.appointmentPaymentCollection;
        this.paymentCollectionDetails = currentVale?.appointmentPaymentCollection?.details != null ? JSON.parse(currentVale.appointmentPaymentCollection.details) : [];
        if (this.paymentCollectionDetails.length > 0) {
          Object.entries(this.stageTemplate).forEach(([stageKey, value]) => {
            const stageIndex = this.paymentCollectionDetails.findIndex((item) => item.type === stageKey);
            let message = (stageIndex === -1) ? value.template : this.paymentCollectionDetails[stageIndex]['message'];
            let isSend = (stageIndex !== -1);
            this.details[stageKey]['message'] = message;
            this.details[stageKey]['isSend'] = isSend;
            this.$refs[`${stageKey}`].setHTML(message);
          });
        } else {
          Object.entries(this.stageTemplate).forEach(([stageKey, value]) => {
            this.details[stageKey]['message'] = value.template;
            this.$refs[`${stageKey}`].setHTML(value.template);
          });
        }
      },
    }
  },
  methods: {
    ...mapActions({
      postAppointmentPaymentCollectionNotifyEmail: 'appAppointments/postAppointmentPaymentCollectionRequestNotifyEmail',
      putAppointmentPaymentCollection: 'appAppointmentPaymentCollections/putAppointmentPaymentCollection'
    }),
    detailStatusCheck(stage) {
      const stageIndex = this.paymentCollectionDetails.findIndex((item) => item.type === `stage${stage}`);
      return {status: stageIndex !== -1, index: stageIndex};

    },

    async sendEmailHandler(stage) {
      if (this.details[`stage${stage}`]['message'] === '') {
        this.showToastMessage({type: 'error', message: 'Please provide message'});
        return;
      }
      if (this.detailStatusCheck(stage).status) {
        this.showToastMessage({type: 'warning', message: 'Email already sent'});
        return;
      }

      if (!this.detailStatusCheck(stage).status) {
        this.paymentCollectionDetails.push({
          type: `stage${stage}`,
          creator: this.$store.getters["appAuthLogin/authUser"].id,
          creation_date: this.getDateTime(),
          message: this.details[`stage${stage}`]['message']
        })
      }
      const paramObj = {
        id: this.paymentCollection.id,
        data: {
          details: JSON.stringify(this.paymentCollectionDetails),
        }
      };
      this.loader(true);
      await this.putAppointmentPaymentCollection(paramObj).then(async (response) => {
        if (response.status === 200) {
          const response = await this.postAppointmentPaymentCollectionNotifyEmail({
            id: this.appointment.id,
            data: {
              stage: stage,
              short_text: this.details[`stage${stage}`]['message']
            }
          });
          this.loader(false);

          if (response.status === 204) {
            document.querySelector('[data-target="#paymentCollectionModal"]').click();
            this.showToastMessage({type: 'success', message: ' Email sent successfully !'});
            this.$emit('getAppointmentList', this.appointmentParams);
          }
        } else {
          this.showToastMessage(response);
        }
      });
    }
  }
}
</script>

<template>
  <div class="modal fade " id="paymentCollectionModal" tabindex="-1" role="dialog"
       aria-labelledby="paymentCollectionModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            <div>
              REF: {{ appointment.reference }}
            </div>
            <div> STATUS: {{ appointment.status }}</div>
          </h1>
          <div class="modal-title white">
            <div>
              PAYMENT STATUS: <br>{{ appointment?.appointmentPayments?.length == 0 ? 'Unpaid' : 'paid' }}
            </div>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset class="col-12">
              <legend>Stage-1</legend>
              <div class="col-12 col-sm-12">
                <div>
                  <div class="form-group">
                    <label for="stageTextarea1">Content</label>
                    <QuillEditor ref="stage1" v-model:content="details.stage1.message" contentType="html"
                                 toolbar="essential"
                                 theme="snow"
                                 placeholder="Enter Description" id="stageTextarea1"/>
                  </div>
                  <div class="d-grid gap-2 col-3 mx-auto mb-2">
                    <button class="btn btn-primary" type="button" @click="sendEmailHandler(1)">Send</button>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="col-12">
              <legend>Stage-2</legend>
              <div class="col-12 col-sm-12">
                <div>
                  <div class="form-group">
                    <label for="stageTextarea2">Content</label>
                    <QuillEditor ref="stage2" v-model:content="details.stage2.message" contentType="html"
                                 toolbar="essential"
                                 theme="snow"
                                 placeholder="Enter Description" id="stageTextarea2"/>
                  </div>
                  <div class="d-grid gap-2 col-3 mx-auto mb-2">
                    <button class="btn btn-primary" type="button" @click="sendEmailHandler(2)">Send</button>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="col-12">
              <legend>Stage-3</legend>
              <div class="col-12 col-sm-12">
                <div>
                  <div class="form-group">
                    <label for="stageTextarea3">Content</label>
                    <QuillEditor ref="stage3" v-model:content="details.stage3.message" contentType="html"
                                 toolbar="essential"
                                 theme="snow"
                                 placeholder="Enter Description" id="stageTextarea3"/>
                  </div>
                  <div class="d-grid gap-2 col-3 mx-auto mb-2">
                    <button class="btn btn-primary" type="button" @click="sendEmailHandler(3)">Send</button>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

        </div>
        <div class="modal-footer border-0 pt-0">

        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<style scoped>

</style>