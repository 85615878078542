<template>
<div class="modal fade" id="appointmentEditNoteModal" tabindex="-1" role="dialog" aria-labelledby="appointmentEditNoteModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Note</h1>
                <button @click="resetData()" type="button" class="close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <div class="row ">
                        <div class="col-12 form-group" v-if="userType && (userType?.length ?? 0 )> 1">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.user_type !== undefined && errors.user_type.length > 0">
                                <p v-for="(error,index) in  errors.user_type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>Note Type</label>
                            <VueMultiselect v-model="selectedAppointmentNoteType" class="" :allow-empty="false" :options="appointmentAllNoteType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.type !== undefined && errors.type.length > 0">
                                <p v-for="(error,index) in  errors.type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea v-model="description" class="form-control" name="" id="" rows="10"></textarea>
                                <div v-if="errors.description !== undefined && errors.description.length > 0">
                                    <p v-for="(error,index) in  errors.description" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" @click="updateNote" class="btn btn-primary">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Update</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters,

} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    name: "AppointmentEditNoteModal",
    mixins: [ShowToastMessage, Loader],
    props: ['note', 'updateEditModalActiveStatus'],
    data() {
        return {
            description: '',
            selectedUserType: '',
            selectedAppointmentNoteType: '',
            errors: {},
            frontEndErrors: {
                userType: [],
                noteType: [],
                noteDescription: [],

            },

        };
    },
    watch: {

        note(currentNote) {
            console.log(currentNote, " currentNote");
            this.description = currentNote.description;
            this.selectedAppointmentNoteType = this.appointmentAllNoteType.find((type) => type.name === currentNote.type);
            this.selectedUserType = this.userType.find((type) => type.name === currentNote.user_type);

            // this.userAllType = [{key:'af',value:1}]
            //  if (currentAllUserTypes.length == 1) {
            //      this.selectedUserType = this.userAllType[0];
            //  }

        }

    },
    computed: {
        ...mapGetters({

            userType: "appUsers/userType",
        }),
        appointmentAllNoteType() {
            return this.$store.getters["appSettings/settingAppointmentNoteType"].value;
        },

    },
    methods: {
        ...mapActions({
            putAppointmentNote: 'appAppointments/putAppointmentNote',
            putAppointmentNoteOnList: 'appAppointments/putAppointmentNoteOnList',
           

        }),
        async updateNote() {
            this.loader(true);
            const dataObj = {
                id: this.note.id,
                data: {
                    appointment_id: this.selectedAppointmentId,
                    user_id: this.$store.getters["appAuthLogin/authUser"].id,
                    user_type: this.selectedUserType.value ?? '',
                    type: this.selectedAppointmentNoteType.value ?? '',
                    description: this.description

                }
            }

            const response = await this.putAppointmentNoteOnList(dataObj);
            if (response.status === 200 || response.status === 201) {
                this.loader(false);
                this.$emit('updateEditModalActiveStatus');
                document.querySelector('[data-target="#appointmentEditNoteModal"]').click();
                this.resetData();
                this.showToastMessage(response.message);
            } else {
                this.loader(false);
                this.errors = response.errors;
            }
            if (response.message) {

                this.showToastMessage(response);
            }

        },
        resetData() {
            document.querySelector('[data-target="#appointmentEditNoteModal"]').click();
            this.$emit('updateEditModalActiveStatus');
            this.selectedUserType = "";
            this.selectedAppointmentNoteType = "";
            this.description = "";
            this.errors = {}
        },
        
    },

};
</script>

<style>
</style>
