<template>
<div class="modal fade" id="appointmentPaymentModal" tabindex="-1" role="dialog" aria-labelledby="appointmentPaymentModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Make Payment</h1>
                <button @click="resetData()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row ">
                        <div class="col-12">

                            <div>
                                <h5 class="mt-1 text-bold-500">Payable amount</h5>
                                <div class="custom-control custom-radio d-flex align-items-center" :style="{marginBottom:'3px'}">
                                    <input type="radio" class="custom-control-input" v-model="paymentAmountType" name="customRadioForAmountTYpe" value="full" id="full">
                                    <label class="custom-control-label text-bold-400" for="full"> ${{parseFloat(duePrice / 100).toFixed(2) }} </label>
                                </div>

                                <div class="custom-control custom-radio d-flex align-items-center">
                                    <input v-model="paymentAmountType" value="custom" type="radio" class="custom-control-input" name="customRadioForAmountTYpe" id="custom">
                                    <label class="custom-control-label text-bold-400" for="custom">custom</label>
                                </div>
                                <div v-if="paymentAmountType === 'custom'">
                                    <div class="row py-1">
                                        <div class="col-12 col-md-12  align-self-center"><input placeholder="Enter payable amount" v-model="customPaymentAmount" type="number" class="w-100 appointment-input-field"></div>
                                    </div>
                                    <!-- showing error -->
                                    <div v-if="frontEndErrors.customPaymentAmount.length > 0" class="text-left">
                                        <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.customPaymentAmount" :key="index">{{error}}</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h5 class="mt-1 text-bold-500">Payment Method</h5>
                                <div v-if="can('card-payment-create')" class="custom-control custom-radio d-flex align-items-center" :style="{marginBottom:'3px'}">
                                    <input type="radio" class="custom-control-input" v-model="paymentType" name="customRadio" value="cardPayment" id="CardPayment">
                                    <label class="custom-control-label text-bold-400" for="CardPayment">Card Payment </label>
                                </div>
                                <div v-if="paymentType === 'cardPayment'" class="row mt-1">
                                    <div class="col-12 p-1" v-if="settingPaymentCardSurcharge.value.status">
                                        <div class="border p-1">
                                            <h6 class="text-danger">Card Surcharge details : </h6>
                                            <div v-for="surCharge in settingPaymentCardSurcharge.value.details" :key="surCharge" class="row">
                                                <div class="col-6 text-left">
                                                    <!-- <p class="text-bold-400 pb-0 mb-0">{{surCharge.name}}: ${{ parseFloat(surCharge.rate ).toFixed(2) }} %</p> -->
                                                    <p class="text-bold-400 pb-0 mb-0">{{surCharge.name}}:
                                                        ${{ parseFloat(parseFloat(totalPayable) * (parseFloat(surCharge.rate) / 100) /100).toFixed(2)  }}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="form-group">
                                            <label for="first-name-icon">Cardholder name</label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" v-model="cardName" class="form-control" name="fname-icon" placeholder="Enter cardholder name">
                                                <div class="form-control-position">
                                                    <i class='bx bx-user'></i>
                                                </div>
                                            </div>
                                            <!-- backend error msg -->
                                            <div v-if="cardTokenBackendErrors.card_name !== undefined && cardTokenBackendErrors.card_name.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_name" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="form-group">
                                            <label for="email-id-icon">Card number</label>
                                            <div class="position-relative has-icon-left">
                                                <input type="email" v-model="cardNumber" class="form-control" name="email-id-icon" placeholder="Enter card number">
                                                <div class="form-control-position">
                                                    <i class='bx bx-credit-card-alt'></i>
                                                </div>
                                            </div>
                                            <!-- backend error msg -->
                                            <div v-if="cardTokenBackendErrors.card_number !== undefined && cardTokenBackendErrors.card_number.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_number" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="contact-info-icon">Expiry Date</label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" v-model="cardExpiryDate" id="expiryDateInput" class="form-control" name="contact-icon" placeholder="MM/YY" maxlength="5" :keyup="this.cardExpiryDate=this.cardExpiryDate.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2')" >
                                                <div class="form-control-position">
                                                    <i class='bx bx-calendar-check'></i>
                                                </div>
                                            </div>
                                            <!-- backend error msg -->
                                            <div v-if="cardTokenBackendErrors.card_expire_month !== undefined && cardTokenBackendErrors.card_expire_month.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_expire_month" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="contact-info-icon">Expiry Year</label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" v-model="cardExpireYear" class="form-control" name="contact-icon" placeholder="Enter expiry date">
                                                <div class="form-control-position">
                                                    <i class='bx bx-calendar-check'></i>
                                                </div>
                                            </div>
                                            
                                            <div v-if="cardTokenBackendErrors.card_expire_year !== undefined && cardTokenBackendErrors.card_expire_year.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_expire_year" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="password-icon">CVV</label>
                                            <div class="position-relative has-icon-left">
                                                <input type="text" ref="cvvInput" v-model="cardCvc" class="form-control" name="contact-icon" placeholder="Enter CVV" maxlength="4">
                                                <div class="form-control-position">
                                                    <i class='bx bx-lock-alt'></i>
                                                </div>
                                            </div>
                                            <!-- backend error msg -->
                                            <div v-if="cardTokenBackendErrors.card_cvc !== undefined && cardTokenBackendErrors.card_cvc.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_cvc" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div v-if="can('cash-payment-create')" class="custom-control custom-radio d-flex align-items-center">
                                        <input v-model="paymentType" value="cashPayment" type="radio" class="custom-control-input" name="customRadio" id="cashPayment">
                                        <label class="custom-control-label text-bold-400" for="cashPayment">Cash payment</label>
                                    </div>
                                    <div v-if="paymentType === 'cashPayment'" class="custom-control custom-checkbox d-flex align-items-baseline ml-1">
                                        <input class="custom-control-input" style="cursor: pointer;" type="checkbox" id="cashReceived" v-model="isCashReceived">
                                        <label class="custom-control-label text-warning" style="cursor: pointer;" for="cashReceived">Cash Received </label>
                                    </div>
                                </div>
                                <div v-if="can('eft-payment-create')" class="custom-control custom-radio d-flex align-items-center">
                                    <input v-model="paymentType" value="eftPayment" type="radio" class="custom-control-input" name="customRadio" id="eftPayment">
                                    <label class="custom-control-label text-bold-400" for="eftPayment">EFT payment</label>
                                </div>
                                <div v-if="paymentType === 'eftPayment'">
                                    <div class="row py-1">
                                        <div class="col-12 col-md-12  align-self-center"><input placeholder="Enter reference" v-model="eftReference" type="text" class="w-100 appointment-input-field"></div>
                                    </div>
                                    <!-- showing error -->
                                    <div v-if="frontEndErrors.eftReference.length > 0" class="text-left">
                                        <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.eftReference" :key="index">{{error}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- showing error -->
                            <div v-if="frontEndErrors.paymentTypeSelection.length > 0" class="text-left pt-2">
                                <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.paymentTypeSelection" :key="index">{{error}}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button @click="payBtnHandler" type="button" class="btn btn-primary">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Create Payment</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import {
    mapActions,
    mapGetters,

} from "vuex";
export default {
    name: "AppointmentPaymentModal",
    props: ['appointment', 'appointmentParams'],
    mixins: [ShowToastMessage, Loader, Authorization],
    data() {
        return {
            isCashReceived: false,
            customPaymentAmount: '',
            paymentAmountType: 'full',
            totalPayable: 0,
            duePrice: 0,

            paymentType: "",
            frontEndErrors: {
                paymentTypeSelection: [],
                eftReference: [],
                paymentAmountType: [],
                customPaymentAmount: []
            },
            cardTokenBackendErrors: {},

            cardName: '',
            cardNumber: '',
            cardExpiryDate:'',
            cardExpireMonth: '',
            cardExpireYear: '',
            cardCvc: '',

            cardTokenResponse: {
                id: '',
                used: '',
                card: {},
                payment_gateway:''
            },
            simplifyPaymentGatewayId: '',

            cardSurcharge: 0,
            eftReference: '',

            appointmentDetails: {
                id: '',
                reference: "",

                customer: {
                    id: "",

                    user: {
                        id: '',
                    },

                },

                appointmentCharges: [],
                appointmentPayments: [],
                // appointmentTotalCharge: 0,

                // appointmentTotalSurCharge: 0,
                // appointmentTotalServicePrice: 0,
                // appointmentTotalDiscount: 0,
                // appointmentTotalGst: 0,

                // appointmentPaymentStatus: null,
                // appointmentTotalPaid: null,

            }

        };
    },
    computed: {
        ...mapGetters({
            payment: 'appPayments/payment',
            authUser: 'appAuthLogin/authUser',
            settingPaymentCardSurcharge: 'appSettings/settingPaymentCardSurcharge',

        }),
    },
    watch: {
        customPaymentAmount(currentAmount) {

            if (parseFloat(currentAmount * 100).toFixed(2) > this.duePrice || (Boolean(currentAmount) === false)) {
                this.frontEndErrors.customPaymentAmount = [];
                this.frontEndErrors.customPaymentAmount.push(`Amount can't greater than ${parseFloat(this.duePrice / 100).toFixed(2)}`);
            } else {
                this.totalPayable = parseInt(currentAmount) * 100;
                this.frontEndErrors.customPaymentAmount = [];
            }

        },
        appointment(currentAppointment) {

            this.customPaymentAmount = '';
            this.paymentAmountType = 'full';
            this.totalPayable = 0;
            this.duePrice = 0;

            this.paymentType = "";
            this.cardTokenBackendErrors = {};
            this.frontEndErrors.paymentTypeSelection = [];
            this.frontEndErrors.eftReference = [];
            this.frontEndErrors.customPaymentAmount = [];

            this.cardName = "";
            this.cardNumber = "";
            this.cardExpireMonth = "";
            this.cardExpireYear = "";
            this.cardCvc = "";

            this.cardTokenResponse.id = "";
            this.cardTokenResponse.used = "";
            this.cardTokenResponse.card = {};
            this.cardTokenResponse.payment_gateway = "";

            this.simplifyPaymentGatewayId = "";
            this.eftReference = "";

            this.appointmentDetails.id = currentAppointment.id ? currentAppointment.id : '';
            // this.appointmentDetails.service.name = currentAppointment.service && currentAppointment.service.name ? currentAppointment.service.name : '';
            // this.appointmentDetails.service.home_price = currentAppointment.service && currentAppointment.service.home_price ? currentAppointment.service.home_price : '';
            // this.appointmentDetails.service.business_price = currentAppointment.service && currentAppointment.service.business_price ? currentAppointment.service.business_price : '';

            this.appointmentDetails.reference = currentAppointment.reference ? currentAppointment.reference : '';

            this.appointmentDetails.customer.user.id = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.id ? currentAppointment.customer.user.id : '';

            this.appointmentDetails.appointmentCharges = (currentAppointment.appointmentCharges) ? currentAppointment.appointmentCharges : [];
            this.appointmentDetails.appointmentPayments = (currentAppointment.appointmentPayments) ? currentAppointment.appointmentPayments : [];

            let charge = 0;

            this.appointmentDetails.appointmentCharges.map((item) => {

                if (item.type == "GST") {
                    charge = charge + item.amount;

                } else if (item.type == "Service Price") {

                    charge = charge + item.amount;

                } else if (item.type == "Surcharge") {
                    charge = charge + item.amount;

                } else if (item.type == "Discount") {
                    charge = charge - item.amount;

                }

            });

            // charge = parseFloat(charge / 100).toFixed(2);

            // this.appointmentDetails.appointmentTotalCharge = charge;

            let paidAmount = 0;

            this.appointmentDetails.appointmentPayments.map((item) => {

                paidAmount = paidAmount + item.payment.total_transaction;

            });

            // paidAmount = parseFloat(paidAmount / 100).toFixed(2);
            // this.appointmentDetails.appointmentTotalPaid = paidAmount;
            // if (charge >= paidAmount) {
            //     this.appointmentPaymentStatus = "Paid";
            // } else if (paidAmount == 0) {
            //     this.appointmentPaymentStatus = "Unpaid";
            // } else {
            //     this.appointmentPaymentStatus = "Partially paid";
            // }
            if (charge > paidAmount) {
                this.totalPayable = charge - paidAmount;
                this.duePrice = charge - paidAmount;
            }

        },
        cardExpiryDate(currentExpiryDate){ 
          this.cardExpireMonth = currentExpiryDate.slice(0,2);
          this.cardExpireYear = currentExpiryDate.slice(-2);
          if(currentExpiryDate.length === 5){
              this.$refs.cvvInput.focus();
            }
        },
    },
    methods: {
        ...mapActions({
            paymentCreate: 'appPaymentGateways/paymentCreate',
            cardTokenCreate: 'appPaymentGateways/cardTokenCreate',
            paymentCreateByToken: 'appPaymentGateways/paymentCreateByToken',
            postPayment: 'appPayments/postPayment',
            postCardPayment: 'appCardPayments/postCardPayment',
            postCashPayment: 'appCashPayments/postCashPayment',
            postEftPayment: 'appEftPayments/postEftPayment',
            postAppointmentPayments: 'appAppointments/postAppointmentPayments',
            postPaymentCreationNotifyEmail: 'appPayments/postPaymentCreationNotifyEmail',

        }),
        async tokenCreate() {

            const data = {
                card_name: this.cardName,
                card_number: this.cardNumber,
                card_expire_month: this.cardExpireMonth,
                card_expire_year: this.cardExpireYear,
                card_cvc: this.cardCvc,
            }
            this.loader(true);
            const response = await this.cardTokenCreate(data);

            if (response.status === 200 || response.status === 201) {

                this.loader(false);
                this.cardTokenBackendErrors = {};
                this.frontEndErrors.paymentTypeSelection = [];
                this.cardTokenResponse.id = response.data.id;
                this.cardTokenResponse.used = response.data.used;
                this.cardTokenResponse.card = response.data.card;
                this.cardTokenResponse.payment_gateway = response.data.payment_gateway;
                if (response.data.used === false) {
                    if (this.settingPaymentCardSurcharge.value ?.status) {
                        let cardType = this.cardTokenResponse.card.type;
                        this.settingPaymentCardSurcharge.value.details.map((item) => {
                            if (cardType.toLowerCase().trim() == item.name.toLowerCase().trim()) {
                                this.cardSurcharge = parseInt(parseFloat(this.totalPayable) * (parseInt(item.rate) / 100));
                            }
                        })
                    }
                    return true;
                }

            } else {

                this.cardTokenResponse.id = '';
                this.cardTokenResponse.used = '';
                this.cardTokenResponse.card = {};
                this.cardTokenResponse.payment_gateway = '';
                this.loader(false);
                this.cardTokenBackendErrors = response.errors;

            }

            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },
        async createPaymentByToken() {
            this.loader(true);
            let data = {
                currency: 'AUD',
                reference: "Admin Appointment payment ",
                amount: this.totalPayable + (this.cardSurcharge),
                description: `Admin paid $ ${this.totalPayable + (this.cardSurcharge)} from admin panel`,
                card_token: this.cardTokenResponse.id,
            }
            const response = await this.paymentCreateByToken(data);
            if (response.status === 200 || response.status === 201) {
                this.simplifyPaymentGatewayId = response.data.id;

                this.loader(false);
                if (response.data.payment_status === 'APPROVED') {

                    return true;
                }

            } else {

                this.loader(false);

            }
            if (response.message) {
                this.showToastMessage(response);
            }
        },

        // card payment api call
        async createCardPayment() {
            const data = {

                payment_id: this.payment.id,
                paid_by: this.appointmentDetails.customer.user.id,
                amount: this.totalPayable,
                card_type: this.cardTokenResponse.card.type,
                card_surcharge: (this.cardSurcharge),
                payment_gateway: this.cardTokenResponse.payment_gateway,
                payment_gateway_id: this.simplifyPaymentGatewayId,
                status: 1

            }
            this.loader(true);
            const response = await this.postCardPayment(data);
            if (response.status === 200 || response.status === 201) {

                this.loader(false);
                return true;

            } else {

                this.loader(false);

            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },

        // cash payment api call
        async createCashPayment() {
            const data = {

                payment_id: this.payment.id,
                paid_by: this.appointmentDetails.customer.user.id,
                paid_to: this.authUser.id,
                handover_date: '',
                amount: this.totalPayable,

            }
            this.loader(true);
            const response = await this.postCashPayment(data);
            if (response.status === 200 || response.status === 201) {

                this.loader(false);
                return true;

            } else {

                this.loader(false);

            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },
        // eft payment api call
        async createEftPayment() {
            const data = {

                payment_id: this.payment.id,
                paid_by: this.appointmentDetails.customer.user.id,
                payment_reference: this.eftReference,
                amount: this.totalPayable,

            }
            this.loader(true);
            const response = await this.postEftPayment(data);
            if (response.status === 200 || response.status === 201) {

                this.loader(false);
                return true;

            } else {

                this.loader(false);

            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },

        async createPayment() {
            const data = {
                // reference: this.simplifyPaymentGatewayId,
                reference: this.appointmentDetails.reference,
                panel: 1
            }
            this.loader(true);

            const response = await this.postPayment(data);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200 || response.status === 201) {

                return true;

            } else {

                this.loader(false);
                return false;

            }

        },

        async createAppointmentPayments() {
            const data = {

                payment_id: this.payment.id,
                appointment_id: this.appointmentDetails.id,

                transaction_date_time: this.payment.created_at,

            };
            this.loader(true);
            const response = await this.postAppointmentPayments(data);
            if (response.message) {

                this.showToastMessage(response);
            }
            if (response.status === 200 || response.status === 201) {
                this.loader(false);
                return true;
            } else {
                this.loader(false);
                return false
            }

        },
        appointmentPaymentEmailNotify() {
            const data = {
                id: this.payment.id,
                params: {
                    notify_customer: 1,
                    notify_internal_user: 1
                }

            };
            this.postPaymentCreationNotifyEmail(data);
        },

        async payByApiCall() {
            if (this.paymentType) {
                this.frontEndErrors.paymentTypeSelection = [];
                if (this.paymentType === 'cardPayment') {

                    this.simplifyPaymentGatewayId = "";

                    let isTokenCreated = await this.tokenCreate();

                    if (isTokenCreated) {
                        const isPaymentCreatedByToken = await this.createPaymentByToken();
                        if (isPaymentCreatedByToken) {

                            const isPaymentCreated = await this.createPayment();
                            if (isPaymentCreated) {
                                const isCardPaymentDone = await this.createCardPayment();
                                if (isCardPaymentDone) {
                                    const isAppointmentPaymentDone = await this.createAppointmentPayments();
                                    if (isAppointmentPaymentDone) {

                                        this.showToastMessage({
                                            message: 'Payment successful',
                                            type: 'success'
                                        });
                                        this.appointmentPaymentEmailNotify()

                                        this.$emit('getAppointmentList', this.appointmentParams);
                                        document.querySelector('[data-target="#appointmentPaymentModal"]').click();

                                    }

                                }

                            }

                        }

                    }

                } else if (this.paymentType === 'cashPayment') {
                    if (this.isCashReceived) {
                        const isPaymentCreated = await this.createPayment();
                        if (isPaymentCreated) {
                            const isCashPaymentDone = await this.createCashPayment();
                            if (isCashPaymentDone) {

                                const isAppointmentPaymentDone = await this.createAppointmentPayments();
                                if (isAppointmentPaymentDone) {

                                    this.showToastMessage({
                                        message: 'Payment successful',
                                        type: 'success'
                                    });

                                    this.appointmentPaymentEmailNotify();

                                    this.$emit('getAppointmentList', this.appointmentParams);
                                    document.querySelector('[data-target="#appointmentPaymentModal"]').click();

                                }
                            }

                        }
                        return;
                    }
                    this.showToastMessage({
                        type: 'error',
                        message: 'If you received the payment in cash then mark on cash received otherwise create appointment without payment'
                    });

                } else {
                    // eft payment postEftPayment
                    if (this.eftReference.length >= 2) {
                        this.frontEndErrors.eftReference = [];

                        const isPaymentCreated = await this.createPayment();
                        if (isPaymentCreated) {
                            const isCashPaymentDone = await this.createEftPayment();
                            if (isCashPaymentDone) {

                                const isAppointmentPaymentDone = await this.createAppointmentPayments();
                                if (isAppointmentPaymentDone) {

                                    this.showToastMessage({
                                        message: 'Payment successful',
                                        type: 'success'
                                    });

                                    this.appointmentPaymentEmailNotify()

                                    this.$emit('getAppointmentList', this.appointmentParams);
                                    document.querySelector('[data-target="#appointmentPaymentModal"]').click();

                                }
                            }

                        }

                    } else {
                        this.frontEndErrors.eftReference = [];
                        this.frontEndErrors.eftReference.push('Please enter reference at least two character');
                    }

                }

            } else {
                this.frontEndErrors.paymentTypeSelection = [];
                this.frontEndErrors.paymentTypeSelection.push('Select a payment')
            }
        },

        async payBtnHandler() {
            if (this.paymentAmountType == 'custom') {
                if (this.customPaymentAmount) {
                    if (parseFloat(this.customPaymentAmount * 100).toFixed(2) > this.duePrice) {
                        this.frontEndErrors.customPaymentAmount = [];
                        this.frontEndErrors.customPaymentAmount.push(`Amount can't greater than ${parseFloat(this.duePrice / 100).toFixed(2)}`);
                    } else {
                        this.frontEndErrors.customPaymentAmount = [];
                        await this.payByApiCall();
                    }
                } else {
                    this.frontEndErrors.customPaymentAmount = [];
                    this.frontEndErrors.customPaymentAmount.push(`Please enter amount and amount can't greater than ${parseFloat(this.duePrice / 100).toFixed(2)}`);
                }

            } else {
                await this.payByApiCall();
            }

        },

    },

};
</script>

<style>
.appointment-input-field {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    width: 20%;
    padding: 4px 15px
}

.appointment-input-field:focus {
    outline: 0 !important;
}

.appointment-input-field:focus {
    border: 1px solid #5a8dee !important;
}
</style>
