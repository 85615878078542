<template>
<div class="modal fade " id="AppointmentInvoiceModal" tabindex="-1" role="dialog" aria-labelledby="AppointmentInvoiceModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Appointment Details </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body ">
                <div>
                    <div class="row" ref="printPdf">
                      
                        <div class="col-12">
                            <div class="">
                                <div class="pb-0 ">
                                  
                                    <div class="row">
                                        <div class="col-sm-8 col-12 text-center text-sm-left order-2 order-sm-1">
                                            <h4 class="text-primary">Invoice: </h4>
                                            <table class="table table-borderless mb-0">

                                                <tbody>
                                                    <tr v-if="appointmentDetails.reference" class="mb-1">
                                                        <td class="py-0 pl-0" style="width: 20%">Ref :</td>
                                                        <td class="py-0 pl-0" style="width: 80%">#<span class="font-weight-light" style="color: #727E8C">{{appointmentDetails.reference}}</span></td>
                                                    </tr>

                                                    <tr v-if="appointmentDetails.date" class="mb-1">
                                                        <td class="py-0 pl-0" style="width: 20%">Date :</td>
                                                        <td class="py-0 pl-0" style="width: 80%"> <span class="font-weight-light" style="color: #727E8C">{{appointmentDetails.date}}</span></td>
                                                    </tr>

                                                    <tr v-if="appointmentDetails.technician.firstName && appointmentDetails.technician.lastName" class="mb-1">
                                                        <td class="py-0 pl-0" style="width: 20%">Served by :</td>
                                                        <td class="py-0 pl-0" style="width: 80%"><span class="font-weight-light" style="color: #727E8C">{{`${appointmentDetails.technician.firstName} ${appointmentDetails.technician.lastName}`}}</span></td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                        <div class="col-sm-4 col-12 text-center text-sm-right order-1 order-sm-2 d-sm-flex justify-content-end mb-1 mb-sm-0">
                                        </div>
                                    </div>
                                    <hr>

                                 
                                    <div class="row invoice-info">
                                        <div class="col-sm-4 col-12 mt-1">
                                            <h6 class="invoice-from">Customer Details</h6>
                                            <div class="mb-1">
                                                <span>{{`${appointmentDetails.customer.user.first_name} ${appointmentDetails.customer.user.last_name}`}}</span>
                                            </div>
                                            <div class="mb-1">
                                                <span>{{`${appointmentDetails.customer.address.street}, ${appointmentDetails.customer.address.suburb}, ${appointmentDetails.customer.address.state}, ${appointmentDetails.customer.address.post_code}, ${appointmentDetails.customer.address.country}`}}</span>
                                            </div>
                                            <div class="mb-1">
                                                <span>{{appointmentDetails.customer.user.email}}</span>
                                            </div>

                                        </div>
                                        <div class="col-sm-4 col-12 mt-1" v-if="appointmentDetails.billingAddress.id">
                                            <h6 class="invoice-from">Billing Address</h6>
                                            <div class="mb-1">
                                                <span>{{`${this.appointmentDetails.billingAddress.street}, ${this.appointmentDetails.billingAddress.suburb}, ${this.appointmentDetails.billingAddress.state}, ${this.appointmentDetails.billingAddress.post_code}, ${this.appointmentDetails.billingAddress.country}`}}</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-12 mt-1">
                                            <h6 class="invoice-to">Service Address</h6>

                                            <div class="mb-1" v-if="appointmentDetails.address.id">
                                                <span>{{`${this.appointmentDetails.address.street}, ${this.appointmentDetails.address.suburb}, ${this.appointmentDetails.address.state}, ${this.appointmentDetails.address.post_code}, ${this.appointmentDetails.address.country}`}}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <!-- product details table-->
                                <div class="invoice-product-details table-responsive">
                                    <table class="table table-borderless mb-0">
                                        <thead>
                                            <tr class="border-0">
                                                <th class="" style="width: 25%">SERVICE</th>
                                                <th class="text-center" style="width: 25%">QTY/HOURS</th>
                                                <th class="text-center" style="width: 25%">UNIT PRICE</th>
                                                <th class="text-right" style="width: 25%">PRICE</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-bold-500">{{appointmentDetails.service.name}} </td>
                                                <td class="text-center">1</td>
                                                <td class="text-bold-500 text-center">${{parseFloat(appointmentDetails.service.home_price * 0.01).toFixed(2)}}</td>
                                                <td class="text-bold-500 text-right">${{parseFloat(appointmentDetails.service.business_price * 0.01).toFixed(2)}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- invoice subtotal -->

                                <div class="pt-0">
                                    <hr>
                                    <table class="table table-borderless mb-0">

                                        <tbody>

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">GST(+) :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%"> <span class="font-weight-light" style="color: #727E8C">${{appointmentDetails.appointmentTotalGst}}</span></td>
                                            </tr>

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Service Price(+) :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%"> <span class="font-weight-light" style="color: #727E8C">${{appointmentDetails.appointmentTotalServicePrice}}</span></td>
                                            </tr>

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Surcharge(+) :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%"> <span class="font-weight-light" style="color: #727E8C">${{appointmentDetails.appointmentTotalSurCharge}}</span></td>
                                            </tr>

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Discount(-) :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%"> <span class="font-weight-light" style="color: #727E8C">${{appointmentDetails.appointmentTotalDiscount}}</span></td>
                                            </tr>

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Grand Total :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%"> <span class="font-weight-light" style="color: #727E8C">${{appointmentDetails.appointmentTotalCharge}}</span></td>
                                            </tr>

                                            <!-- <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Total :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%">$<span class="font-weight-light" style="color: #727E8C">{{appointmentDetails.appointmentTotalCharge}}</span></td>
                                            </tr> -->

                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Paid :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%">$<span class="font-weight-light" style="color: #727E8C">{{appointmentDetails.appointmentTotalPaid}}</span></td>
                                            </tr>
                                            <tr class="mb-1">
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 90%">Due :</td>
                                                <td class="py-0 pl-0 pr-0 text-right" style="width: 100%">$<span class="font-weight-light" style="color: #727E8C">{{ appointmentDetails.appointmentTotalCharge - appointmentDetails.appointmentTotalPaid }}</span></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                  
                                </div>
                            </div>
                        </div>

                     
                    </div>
                    

                </div>
            </div>
            <div class="modal-footer border-0 py-1">
                <button @click="print()" type="button" class="btn btn-secondary">
                    <span class="d-none d-sm-block">Print Invoice</span>
                </button>
              

                <button type="button" class="btn btn-primary" data-dismiss="modal">

                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "AppointmentInvoiceModal",
    props: ['appointment'],
    components: {

    },
    data() {
        return {
            appointmentDetails: {
                id: '',
                uuid:'',
                reference: "",
                type: "",
                status: "",
                platform: "",
                parking: "",
                preference: "",
                date: "",
                time: "",
                length: null,
                service: {
                    id: '',
                    name: "",
                    code: "",
                    description: "",
                    home_price: '',
                    business_price: '',
                    commission: "",
                    status: "",
                    // image:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png",
                    // thumbnail:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png"
                },
                business: null,
                order: null,
                customer: {
                    id: 201,
                    type: "",
                    status: "",
                    referral_code: "",
                    newsletter_subscription: "",
                    user: {
                        id: '',
                        first_name: "",
                        last_name: "",
                        email: null,
                        phone_number: "",

                        // avatar:"http:\/\/localhost:8000\/default\/images\/users\/avatar.png"
                    },
                    address: {
                        id: '',
                        street: "",
                        suburb: "",
                        state: "",
                        post_code: "",
                        country: ""
                    }
                },
                address: {
                    id: '',
                    street: "",
                    suburb: "",
                    state: "",
                    post_code: "",
                    country: ""
                },
                technician: {
                    firstName: '',
                    lastName: ''
                },
                appointmentCharges: [],
                appointmentPayments: [],
                appointmentTotalCharge: 0,

                appointmentTotalSurCharge: 0,
                appointmentTotalServicePrice: 0,
                appointmentTotalDiscount: 0,
                appointmentTotalGst: 0,

                appointmentPaymentStatus: null,
                appointmentTotalPaid: null,
                billingAddress: {
                    id: '',
                    street: "",
                    suburb: "",
                    state: "",
                    post_code: "",
                    country: ""
                },
                appointmentNotes: [],
                appointmentCreator: {
                    id: '',
                    panel: ""
                },
                appointmentConsent: null,

            }
        }
    },
    watch: {
        appointment(currentAppointment) {

            this.appointmentDetails.id = currentAppointment.id ? currentAppointment.id : '';
            this.appointmentDetails.uuid = currentAppointment.uuid ? currentAppointment.uuid : '';
            this.appointmentDetails.service.name = currentAppointment.service && currentAppointment.service.name ? currentAppointment.service.name : '';
            this.appointmentDetails.service.home_price = currentAppointment.service && currentAppointment.service.home_price ? currentAppointment.service.home_price : '';
            this.appointmentDetails.service.business_price = currentAppointment.service && currentAppointment.service.business_price ? currentAppointment.service.business_price : '';
            this.appointmentDetails.type = currentAppointment.type ? currentAppointment.type : '';
            this.appointmentDetails.reference = currentAppointment.reference ? currentAppointment.reference : '';
            this.appointmentDetails.date = currentAppointment.date ? currentAppointment.date : '';
            this.appointmentDetails.parking = currentAppointment.parking ? currentAppointment.parking : '';
            this.appointmentDetails.appointmentNotes = currentAppointment.appointmentNotes ? currentAppointment.appointmentNotes : '';
            this.appointmentDetails.status = currentAppointment.status ? currentAppointment.status : '';

            this.appointmentDetails.customer.user.first_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.first_name ? currentAppointment.customer.user.first_name : '';
            this.appointmentDetails.customer.user.last_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.last_name ? currentAppointment.customer.user.last_name : '';
            this.appointmentDetails.customer.user.email = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.email ? currentAppointment.customer.user.email : '';
            this.appointmentDetails.customer.user.phone_number = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.phone_number ? currentAppointment.customer.user.phone_number : '';

            this.appointmentDetails.customer.address.id = (currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address && currentAppointment.customer.address.id) ? currentAppointment.customer.address.id : '';
            this.appointmentDetails.customer.address.street = (currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.street) ? currentAppointment.customer.address.street : '';
            this.appointmentDetails.customer.address.suburb = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.suburb ? currentAppointment.customer.address.suburb : '';
            this.appointmentDetails.customer.address.state = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.state ? currentAppointment.customer.address.state : '';
            this.appointmentDetails.customer.address.post_code = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.state ? currentAppointment.customer.address.state : '';
            this.appointmentDetails.customer.address.country = currentAppointment.customer && currentAppointment.customer.address && currentAppointment.customer.address.country ? currentAppointment.customer.address.country : '';

            this.appointmentDetails.technician.firstName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.first_name ? currentAppointment.technicianAppointment.technician.user.first_name : '';
            this.appointmentDetails.technician.lastName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.last_name ? currentAppointment.technicianAppointment.technician.user.last_name : '';

            this.appointmentDetails.billingAddress.id = currentAppointment.billingAddress && currentAppointment.billingAddress.id ? currentAppointment.billingAddress.id : '';
            this.appointmentDetails.billingAddress.street = currentAppointment.billingAddress && currentAppointment.billingAddress.street ? currentAppointment.billingAddress.street : '';
            this.appointmentDetails.billingAddress.suburb = currentAppointment.billingAddress && currentAppointment.billingAddress.suburb ? currentAppointment.billingAddress.suburb : '';
            this.appointmentDetails.billingAddress.state = currentAppointment.billingAddress && currentAppointment.billingAddress.state ? currentAppointment.billingAddress.state : '';
            this.appointmentDetails.billingAddress.post_code = currentAppointment.billingAddress && currentAppointment.billingAddress.post_code ? currentAppointment.billingAddress.post_code : '';
            this.appointmentDetails.billingAddress.country = currentAppointment.billingAddress && currentAppointment.billingAddress.country ? currentAppointment.billingAddress.country : '';

            this.appointmentDetails.address.id = currentAppointment.address && currentAppointment.address.id ? currentAppointment.address.id : '';
            this.appointmentDetails.address.street = currentAppointment.address && currentAppointment.address.street ? currentAppointment.address.street : '';
            this.appointmentDetails.address.suburb = currentAppointment.address && currentAppointment.address.suburb ? currentAppointment.address.suburb : '';
            this.appointmentDetails.address.state = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
            this.appointmentDetails.address.post_code = currentAppointment.address && currentAppointment.address.post_code ? currentAppointment.address.post_code : '';
            this.appointmentDetails.address.country = currentAppointment.address && currentAppointment.address.country ? currentAppointment.address.country : '';

            this.appointmentDetails.appointmentCharges = (currentAppointment.appointmentCharges) ? currentAppointment.appointmentCharges : [];
            this.appointmentDetails.appointmentPayments = (currentAppointment.appointmentPayments) ? currentAppointment.appointmentPayments : [];

            let charge = 0;

            let appointmentTotalServicePrice = 0;
            let appointmentTotalSurCharge = 0;
            let appointmentTotalGst = 0;
            let appointmentTotalDiscount = 0;

            this.appointmentDetails.appointmentCharges.map((item) => {

                if (item.type == "GST") {
                    charge = charge + item.amount;
                    appointmentTotalGst = appointmentTotalGst + item.amount;

                } else if (item.type == "Service Price") {

                    charge = charge + item.amount;
                    appointmentTotalServicePrice = appointmentTotalServicePrice + item.amount;

                } else if (item.type == "Surcharge") {
                    charge = charge + item.amount;
                    appointmentTotalSurCharge = appointmentTotalSurCharge + item.amount;

                } else if (item.type == "Discount") {
                    charge = charge - item.amount;
                    appointmentTotalDiscount = appointmentTotalDiscount + item.amount;
                }

            });

            charge = parseFloat(charge / 100).toFixed(2);

            appointmentTotalServicePrice = parseFloat(appointmentTotalServicePrice / 100).toFixed(2);
            appointmentTotalSurCharge = parseFloat(appointmentTotalSurCharge / 100).toFixed(2);
            appointmentTotalGst = parseFloat(appointmentTotalGst / 100).toFixed(2);
            appointmentTotalDiscount = parseFloat(appointmentTotalDiscount / 100).toFixed(2);

            this.appointmentDetails.appointmentTotalCharge = charge;

            this.appointmentDetails.appointmentTotalSurCharge = appointmentTotalSurCharge;
            this.appointmentDetails.appointmentTotalGst = appointmentTotalGst;
            this.appointmentDetails.appointmentTotalDiscount = appointmentTotalDiscount;
            this.appointmentDetails.appointmentTotalServicePrice = appointmentTotalServicePrice;

            let paidAmount = 0;
          
            this.appointmentDetails.appointmentPayments.map((item) => {
             
                paidAmount = paidAmount + item.payment.total_transaction;

            });

            paidAmount = parseFloat(paidAmount / 100).toFixed(2);
            this.appointmentDetails.appointmentTotalPaid = paidAmount;
            if (charge >= paidAmount) {
                this.appointmentPaymentStatus = "Paid";
            } else if (paidAmount == 0) {
                this.appointmentPaymentStatus = "Unpaid";
            } else {
                this.appointmentPaymentStatus = "Partially paid";
            }
           

        },

      

    },
    computed: {

    },
    methods: {
        print() {
           document.querySelector('[data-target="#AppointmentInvoiceModal"]').click();

            this.$router.push({
                name: 'appAppointmentInvoice',
                params: {
                    uuid: this.appointmentDetails.uuid

                }
            })

        }
    }

}
</script>

<style>

</style>
