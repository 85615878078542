<template>
<div class="modal fade " id="orderDetailsModal" tabindex="-1" role="dialog" aria-labelledby="orderDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white ">Order Details <button class="btn btn-sm btn-danger" @click="viewOrderFullDetailsHandler(orderDetails.order_no)">View on order list</button></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h4>
                        <span class="text-secondary text-light" v-if="orderDetails.order_no">({{ orderDetails.order_no }})</span>

                        <span v-if="orderDetails?.appointment">
                            <span><i class="bx bx-link" style="font-size: 1.5rem;"></i> </span>
                            <span v-if="orderDetails.appointment.type === 'Home'">
                                <i class="bx bx-home" style="font-size: 1.5rem;"></i>
                            </span>
                            <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i> </span>
                            <span class="text-secondary text-light">({{ orderDetails.appointment.reference }})</span>
                        </span>
                    </h4>
                </div>

                <div>

                    <div class="row">
                        <div class="col-md-6" v-if="orderDetails.user !== undefined">
                            <div>
                                <span class=""><i class='bx bx-user' :style="{fontSize:'15px'}"></i></span>

                                <span class="text-capitalize">{{`${orderDetails?.user?.first_name} ${orderDetails?.user?.last_name}`}}</span>
                            </div>
                            <div>
                                <span class="" v-if="orderDetails?.user?.email"><i class='bx bx-mail-send' :style="{fontSize:'15px'}"></i></span>
                                <span>{{ orderDetails?.user?.email }}</span>
                            </div>
                            <div>
                                <span class="" v-if="orderDetails?.user?.phone_number"><i class='bx bx-phone' :style="{fontSize:'15px'}"></i></span>
                                <span>{{ orderDetails?.user?.phone_number }}</span>
                            </div>
                            <div>
                                <span class="" v-if="orderDetails?.user?.customer?.address?.id"><i class='bx bx-map' :style="{fontSize:'15px'}"></i> </span>
                                <span>{{ fullAddress(orderDetails.user.customer) }}</span>
                            </div>

                        </div>
                        <div class="col-md-6 d-flex justify-content-end align-items-end">
                            <div>

                                <div v-if="orderPayment !== undefined">
                                    <h4 class="">${{ parseFloat(orderPayment.total / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small></h4>
                                </div>

                                <div class="">
                                    <span class="font-weight-bolder">Status:</span> <span class="badge" :class="orderDetails.status=== 'Pending'?'badge-secondary':'badge-danger'">
                                        {{ orderDetails.status }}</span>
                                </div>
                                <div class="" v-if="orderPayment!== undefined">
                                    <span class="font-weight-bolder">Payment Status:</span> <span class="badge " :class="orderPayment.remaining === 0 ? 'badge-success' : (orderPayment.paid === 0 ? 'badge-danger' :'badge-primary' )"> {{ orderPayment.remaining === 0 ? 'Full Paid' : (orderPayment.paid === 0 ? 'Unpaid' :'Partially Paid' ) }}</span>
                                </div>
                                <div class="font-weight-bolder" v-if="orderPayment.remaining">
                                    Remaining amount: <span class="font-weight-normal text-warning">${{(orderPayment.remaining/100).toFixed(2)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-12  py-1 text-dark" :style="{backgroundColor:'#edf2f9'}">
                            <h5 class="text-center mb-0 font-weight-bold" v-if=" orderDetails.delivery_date">Delivery: {{
                    new Date(`${orderDetails.delivery_date}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</h5>
                            <p class="text-center mb-0"> {{
                    new Date(`${orderDetails.order_date}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</p>
                        </div>
                    </div>

                    <div class="row pt-1">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>PRODUCT</th>
                                            <th>QTY</th>
                                            <th>UNIT PRICE</th>
                                            <th>PRICE</th>

                                        </tr>
                                    </thead>
                                    <tbody v-if="orderDetails.details!==undefined">
                                        <tr v-for="(cartDetails) in orderDetails.details.cart_data" :key="cartDetails.product_id">
                                            <td class="text-capitalize text-bold-500">{{ cartDetails.name }}({{ cartDetails?.product_price?.condition_name }})
                                                <div v-if="orderDetails.product_items !== undefined && orderDetails.product_items.length !== 0">
                                                    <ul>
                                                        <div v-for="(singleItem) in orderDetails.product_items" :key="singleItem.id">
                                                            <li v-if="singleItem.item_obj.condition === cartDetails.product_price.condition_name ">
                                                                {{ singleItem.item_obj.serial_numbery }}
                                                            </li>

                                                        </div>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>{{ cartDetails.product_price.quantity }}</td>
                                            <td class="text-bold-500">
                                                ${{ parseFloat((cartDetails?.product_price?.amount) / 100).toFixed(2) }}
                                            </td>
                                            <td class="text-bold-500">
                                                ${{ parseFloat((cartDetails?.product_price?.sub_total) / 100).toFixed(2) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end align-items-end">
                        <div>
                            <div>
                                <span>Sub Total :</span> ${{ parseFloat(subTotal / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small>
                            </div>
                            <div v-for="(charge,index) in orderPayment.chargesDetails" :key="index">
                                <span class="">{{ charges?.[charge['name']]?.['name'] }}
                                    ({{ charges?.[charge['name']]?.['sign'] }}) : </span>
                                ${{ parseFloat(charge.amount / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small>
                            </div>
                            <div>
                                <span>Grand Total :</span> ${{ parseFloat(orderPayment.total / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small>
                            </div>
                            <div>
                                <span>Paid :</span> ${{ parseFloat(orderPayment.paid / 100).toFixed(2) }} <small style="font-size: 0.8rem;">AUD</small>
                            </div>
                        </div>

                    </div>
                </div>

                <div v-if="orderDetails?.orderNotes?.length" class="p-2" :style="{backgroundColor:'#fff4a3'}">
                    <p v-for="(note,index) in orderDetails.orderNotes" :style="{color:'#000'}" :key="note.id" class="mb-0">
                        <span v-if="index === 0" class="font-weight-bolder">Notes: </span>{{ note.description }}
                    </p>
                </div>
            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "OrderDetailsModal",
    props: [],
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            getOrderParams: {
                with_relation: [
                    'user',
                    'user.customer.address',
                    'user.customer.address.business',
                    'orderCharges',
                    'orderPayments.payment',
                    'orderPaymentsByReference',
                    'appointment.technicianAppointment.technician.user',
                    'shippingAddress.business',
                    'billingAddress.business',
                    'orderNotes',
                    'orderProductItems.productItem.product'
                ],
            },
            orderPayment: {
                total: '',
                paid: '',
                remaining: '',
                chargesDetails: '',
            },
            subTotal: 0,
        }
    },
    watch: {
        orderDetails(currentValue) {
            currentValue.details = JSON.parse(currentValue.details)
            this.subTotal = currentValue.details.cart_data.reduce(function (acc, cur) {
                return acc + cur.product_price.sub_total
            }, 0);
            this.getOrderCalculation(currentValue);
        }
    },
    computed: {
        ...mapGetters({

            orderDetails: "appOrders/order"

        }),

        charges() {
            return {
                gst_charge: {
                    name: 'GST Charge',
                    sign: '+'
                },
                order_delivery_charge: {
                    name: 'Delivery Charge',
                    sign: '+'
                },
                applied_discount: {
                    name: 'Applied Discount',
                    sign: '-'
                },
                coupon_discount: {
                    name: 'Coupon Discount',
                    sign: '-'
                },
                credited_payment_discount: {
                    name: 'Credited Discount',
                    sign: '-'
                },

            };
        }
    },
    methods: {
        ...mapActions({

            getOrder: 'appOrders/getOrder',
            postOrderInvoice: 'appOrders/postOrderInvoice'

        }),
        async viewOrderFullDetailsHandler(orderNo) {
            document.querySelector('[data-target="#orderDetailsModal"]').click();
            await this.$router.push({
                name: 'appOrderList',
                params: {
                    orderNo: orderNo,

                }
            });

        },
        fullAddress(customerObj) {
            let businessName = customerObj ?.address ?.business ?.name ?? '';
            let addressStreet = customerObj ?.address ?.street;
            let addressSuburb = customerObj ?.address ?.suburb;
            let addressState = customerObj ?.address ?.state.toUpperCase();
            let addressPostCode = customerObj ?.address ?.post_code;
            let addressCountry = customerObj ?.address ?.country;
            return customerObj ?.type === 'Business' ?
                `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
                `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
        },
        async postSingleOrderInvoice(uuid) {
            let paramObj = {
                uuid: uuid,
                params: this.getOrderParams,
            };
            await this.postOrderInvoice(paramObj).then(async (response) => {
                this.loader(false);
                if (response && response.status === 404) {
                    await this.$router.push({
                        name: '404'
                    });
                }
                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },
        print() {
            let idElement = document.getElementById('printArea');
            this.printElement(idElement);
        },

        getOrderCalculation(order) {
            let orderCharge = this.calculateOrderCharges(order);
            let orderPayment = this.calculateOrderPayments(order);
            let orderChargesDetails = this.getOrderChargesDetails(order);
            /**
             *  TODO: will be shown product Item name & quantity on order detail view like that
             *  products name with the product condition type from orderProductItem array
             */
            /*let xyz =  this.groupItemByProductAndCondition(order);
            console.log(xyz);*/
            let remaining = orderCharge - orderPayment;
            this.orderPayment = {
                total: orderCharge,
                paid: orderPayment,
                remaining: remaining,
                chargesDetails: orderChargesDetails
            };

        },

        calculateOrderCharges(singleOrder) {
            const decreaseType = ['Discount', 'Coupon']
            if (singleOrder.status === 'Pending') {
                return singleOrder ?.details ?.grand_total ?? 0;
            } else {
                return singleOrder.orderCharges.reduce(function (accu, cur) {
                    accu = (decreaseType.includes(cur.type)) ? accu - cur.amount : accu + cur.amount
                    return accu
                }, 0);
            }

        },

        calculateOrderPayments(singleOrder) {
            if (singleOrder.status === 'Pending') {
                return singleOrder.orderPaymentsByReference.reduce(function (acc, cur) {
                    return acc + cur.total_transaction
                }, 0);
            } else {
                return singleOrder.orderPayments.reduce(function (accu, cur) {
                    return (!('payment' in cur) && (!cur.payment)) ? 0 : accu + cur.payment.total_transaction
                }, 0);
            }

        },

        getOrderChargesDetails(singleOrder) {
            const chargesArray = [
                'gst_charge',
                'order_delivery_charge',
                'applied_discount',
                'coupon_discount',
                'credited_payment_discount',
            ];

            return chargesArray.filter((chargeName) =>
                chargeName in singleOrder.details && singleOrder.details[chargeName].applied_status === true).map((chargeName) => {
                return {
                    name: chargeName,
                    amount: singleOrder.details[chargeName].amount
                }
            });
        },
    },
    async mounted() {

        // this.loader(true);
        // const routeParams = await this.$route.params;
        // await this.postSingleOrderInvoice(routeParams.uuid);
        // this.loader(false);
    },

}
</script>

<style>

</style>
