<template>
<div class="modal fade " id="assignTechnicianModal" tabindex="-1" role="dialog" aria-labelledby="assignTechnicianModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Choose Status</h1>
                <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row" v-if="(availableTechnicians.technicians !== undefined &&  availableTechnicians.technicians.length > 0)">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Technician</label>
                                    <VueMultiselect v-model="selectedTechnician" :allow-empty="false" class="multiselect-blue" :options="availableTechniciansWithDistanceAndDuration" :close-on-select="true" placeholder="Select a status" label="name" track-by="id" :show-labels="false" />
                                </div>

                                <div v-if="frontendErrors.selectedTechnician.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedTechnician" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="assignTechnician">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "AssignTechnicianModal",
    mixins: [ShowToastMessage, Loader],
    props: ['appointment', 'appointmentParams', ],

    data() {
        return {

            selectedTechnician: '',

            frontendErrors: {

                selectedTechnician: [],

            },

        }
    },
    watch: {
        appointment() {

            this.selectedTechnician = '';
            this.frontendErrors.selectedTechnician = [];

        },
        selectedTechnician() {
            this.frontendErrors.selectedTechnician = [];
        }

    },
    computed: {
        ...mapGetters({
            availableTechnicians: 'appTechnicians/availableTechnicians',

            // userAllTypes: "appUsers/userAllTypes",
        }),

      availableTechniciansWithDistanceAndDuration(){
        return (this.availableTechnicians?.technicians ?? []).map((technician) => ({name:`${technician.name} ( ${technician.distance} by ${technician.duration})`, id:technician.id,}));
      },

    },

    methods: {
        ...mapActions({
            getAvailableTechnicians: 'appTechnicians/getAvailableTechnicians',
            postAppointmentTechnician: 'appAppointments/postAppointmentTechnician',
            postAppointmentHistory: 'appAppointments/postAppointmentHistory',

        }),

        async createAppointmentHistory() {
            const data = {
                user_id: this.$store.getters["appAuthLogin/authUser"].id,
                appointment_id: this.appointment.id,
                technician_id: this.selectedTechnician.id !== undefined ? this.selectedTechnician.id : '',
                panel: 1,
                status: 0,
            };
            this.loader(true);
            const response = await this.postAppointmentHistory(data);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200 || response.status === 201) {

                this.showToastMessage({
                    message: 'Appointment history created',
                    type: 'success'
                });

                document.querySelector('[data-target="#assignTechnicianModal"]').click();
                this.$emit('getAppointmentList', this.appointmentParams);

            }

        },

        async createAppointmentTechnician() {
            const data = {
                technician_id: this.selectedTechnician.id !== undefined ? this.selectedTechnician.id : '',
                appointment_id: this.appointment.id,

            };
            this.loader(true);
            const response = await this.postAppointmentTechnician(data);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200 || response.status === 201) {
                this.showToastMessage({
                    message: 'Appointment technician created',
                    type: 'success'
                });

            }
            this.loader(false);
            await this.createAppointmentHistory();

        },

        async assignTechnician() {
            if (this.selectedTechnician) {
                this.frontendErrors.selectedTechnician = [];
                await this.createAppointmentTechnician();
            } else {
                this.frontendErrors.selectedTechnician = [];
                this.frontendErrors.selectedTechnician.push("Please select a technician");
            }

        },

    }

}
// this.$store.getters["appAuthLogin/authUser"].id
</script>

<style>
</style>
