<template>
<div class="modal fade " id="appointmentQuickViewModal" tabindex="-1" role="dialog" aria-labelledby="appointmentQuickViewModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Appointment Details </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h3 class=""> <span v-if="appointmentDetails.type == 'Home'"><i class="bx bx-home" style="font-size: 1.5rem;"></i></span> <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i></span> <span v-if="appointmentDetails.service.name">{{appointmentDetails.service.name}}</span> <span class="text-secondary text-light" v-if="appointmentDetails.reference">({{appointmentDetails.reference}})</span> <span></span> </h3>

                </div>
                <div>
                    <div class="row">
                        <div class="col-md-6">

                            <div>
                                <span class="" v-if="appointmentDetails.customer.user.first_name  && appointmentDetails.customer.user.last_name"><i class='bx bx-user' :style="{fontSize:'15px'}"></i> </span> <span>{{`${appointmentDetails.customer.user.first_name} ${appointmentDetails.customer.user.last_name}`}}</span>
                            </div>
                            <div>
                                <span class="" v-if="appointmentDetails.customer.user.email"><i class='bx bx-mail-send' :style="{fontSize:'15px'}"></i></span> <span>{{appointmentDetails.customer.user.email}}</span>
                            </div>
                            <div>
                                <span class="" v-if="appointmentDetails.customer.user.phone_number"><i class='bx bx-phone' :style="{fontSize:'15px'}"></i></span> <span>{{appointmentDetails.customer.user.phone_number}}</span>
                            </div>
                            <div>
                                <span class="" v-if="appointmentDetails.customer.address.id"><i class='bx bx-map' :style="{fontSize:'15px'}"></i> </span> <span v-if="appointmentDetails.customer.address.street"></span>{{ appointmentDetails.customer.address.street}} , <span v-if="appointmentDetails.customer.address.suburb"> <span>{{appointmentDetails.customer.address.suburb}} </span> <span v-if="appointmentDetails.customer.address.state">{{appointmentDetails.customer.address.state}},</span> <span v-if="appointmentDetails.customer.address.country"></span> {{appointmentDetails.customer.address.country}} </span>
                            </div>

                        </div>
                        <div class="col-md-6 d-flex justify-content-end align-items-end">
                            <div>

                                <div class="d-flex">
                                    <span class=""><i class='bx bxs-parking bg-warning rounded-sm text-white' :style="{fontSize:'28px',padding:'5px 5px'}"></i></span>
                                    <div class="" :style="{paddingLeft:'5px'}">
                                        <small class="py-0 my-0">PARKING</small>
                                        <p class="mb-0"><small>{{ appointmentDetails.parking ? appointmentDetails.parking : 'No'}}</small></p>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="">${{appointmentDetails.appointmentTotalCharge}} <small style="font-size: 0.8rem;">AUD</small> </h4>
                                </div>

                                <div class="">
                                    <span class="font-weight-bolder">Status:</span> <span class="badge badge-secondary">{{appointmentDetails.status}}</span>
                                </div>
                                <div class="">
                                    <span class="font-weight-bolder">Payment Status:</span> <span class="badge badge-primary">{{appointmentDetails.appointmentPaymentStatus}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-1">
                        <div class="col-12  py-1 text-dark" :style="{backgroundColor:'#edf2f9'}">
                            <h4 class="text-center mb-0 font-weight-bold" v-if=" appointmentDetails.date">{{ new Date(`${appointmentDetails.date}`).toLocaleDateString("en-AU", {day: "numeric",month: "short",year: "numeric",})  }}</h4>
                            <p class="text-center mb-0"> {{ new Date(`${appointmentDetails.date} ${appointmentDetails.time}`).toLocaleTimeString('en-AU')  }}</p>
                        </div>
                    </div>
                    <div class="row pt-1">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>SERVICE</th>
                                            <th>QTY/HOURS</th>
                                            <th>UNIT PRICE</th>
                                            <th>PRICE</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-bold-500">{{appointmentDetails.service.name}} </td>
                                            <td>{{appointmentDetails.quantity}}</td>
                                            <td class="text-bold-500">${{parseFloat(appointmentDetails.unitPrice * 0.01).toFixed(2)}}</td>
                                            <td class="text-bold-500">${{parseFloat(appointmentDetails.servicePrice * 0.01).toFixed(2)}}</td>

                                        </tr>

                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="p-2" :style="{backgroundColor:'rgb(255, 244, 163,0.4)'}">

                    <p v-for="(note,index) in appointmentDetails.appointmentNotes" :style="{color:'#000'}" :key="note.id" class="mb-0">
                        <span v-if="index == 0" class="font-weight-bolder">Notes: </span>{{note.description}}

                    </p>
                </div>
                <div class="py-3">
                    <h4 class="text-secondary" v-if=" appointmentDetails.appointmentConsents.length > 0">Consent</h4>
                    <div v-for="(appointmentConsent,index) in appointmentDetails.appointmentConsents" :key="index">
                        <p class="mb-0"><strong>Status: </strong> {{appointmentConsent.status}} </p>
                        <p class="mb-0"><strong>Issue Notes: </strong> {{appointmentConsent.remark}}</p>
                        <p class="mb-0"><strong>Sinature: </strong>
                            <img :src="appointmentConsent.signature" alt="customer-signature" style="width:100px;">
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "AppointmentQuickViewModal",
    props: ['appointment'],
    data() {
        return {
            appointmentDetails: {
                id: '',
                reference: "",
                quantity:'',
                type: "",
                status: "",
                platform: "",
                parking: "",
                preference: "",
                date: "",
                time: "",
                length: null,
                service: {
                    id: '',
                    name: "",
                    code: "",
                    description: "",
                    home_price: '',
                    business_price: '',
                    commission: "",
                    status: "",
                    // image:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png",
                    // thumbnail:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png"
                },
                business: null,
                order: null,
                customer: {
                    id: 201,
                    type: "",
                    status: "",
                    referral_code: "",
                    newsletter_subscription: "",
                    user: {
                        id: '',
                        first_name: "",
                        last_name: "",
                        email: null,
                        phone_number: "",

                        // avatar:"http:\/\/localhost:8000\/default\/images\/users\/avatar.png"
                    },
                    address: {
                        id: '',
                        street: "",
                        suburb: "",
                        state: "",
                        post_code: "",
                        country: ""
                    }
                },
                appointmentCharges: [],
                appointmentPayments: [],
                appointmentTotalCharge: 0,
                appointmentPaymentStatus: null,
                appointmentNotes: [],
                appointmentConsents: [],
                appointmentCreator: {
                    id: '',
                    panel: ""
                },
                appointmentConsent: null,
                unitPrice: '',
                servicePrice: '',

            }
        }
    },
    watch: {
        appointment(currentAppointment) {

            this.appointmentDetails.id = currentAppointment.id ? currentAppointment.id : '';
            this.appointmentDetails.quantity = currentAppointment.quantity ? currentAppointment.quantity : '';
            this.appointmentDetails.unitPrice = currentAppointment.unit_price ? currentAppointment.unit_price : '';
            this.appointmentDetails.service.name = currentAppointment.service && currentAppointment.service.name ? currentAppointment.service.name : '';
            this.appointmentDetails.service.home_price = currentAppointment.service && currentAppointment.service.home_price ? currentAppointment.service.home_price : '';
            this.appointmentDetails.service.business_price = currentAppointment.service && currentAppointment.service.business_price ? currentAppointment.service.business_price : '';
            this.appointmentDetails.type = currentAppointment.type ? currentAppointment.type : '';
            this.appointmentDetails.reference = currentAppointment.reference ? currentAppointment.reference : '';
            this.appointmentDetails.date = currentAppointment.date ? currentAppointment.date : '';
            this.appointmentDetails.time = currentAppointment.time ? currentAppointment.time : '';
            this.appointmentDetails.parking = currentAppointment.parking ? currentAppointment.parking : '';
            this.appointmentDetails.appointmentNotes = currentAppointment.appointmentNotes ? currentAppointment.appointmentNotes : '';
            this.appointmentDetails.status = currentAppointment.status ? currentAppointment.status : '';

            this.appointmentDetails.customer.user.first_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.first_name ? currentAppointment.customer.user.first_name : '';
            this.appointmentDetails.customer.user.last_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.last_name ? currentAppointment.customer.user.last_name : '';
            this.appointmentDetails.customer.user.email = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.email ? currentAppointment.customer.user.email : '';
            this.appointmentDetails.customer.user.phone_number = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.phone_number ? currentAppointment.customer.user.phone_number : '';

            this.appointmentDetails.customer.address.id = (currentAppointment.address && currentAppointment.address.id) ? currentAppointment.address.id : '';
            this.appointmentDetails.customer.address.street = (currentAppointment.address && currentAppointment.address.street) ? currentAppointment.address.street : '';
            this.appointmentDetails.customer.address.suburb = currentAppointment.address && currentAppointment.address.suburb ? currentAppointment.address.suburb : '';
            this.appointmentDetails.customer.address.state = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
            this.appointmentDetails.customer.address.country = currentAppointment.address && currentAppointment.address.country ? currentAppointment.address.country : '';

            this.appointmentDetails.appointmentCharges = (currentAppointment.appointmentCharges) ? currentAppointment.appointmentCharges : [];
            this.appointmentDetails.appointmentConsents = (currentAppointment.appointmentConsents) ? currentAppointment.appointmentConsents : [];
            this.appointmentDetails.appointmentPayments = (currentAppointment.appointmentPayments) ? currentAppointment.appointmentPayments : [];
            let charge = 0;
            this.appointmentDetails.appointmentCharges.map((item) => {

                if (item.type == "GST") {
                    charge = charge + item.amount;
                } else if (item.type == "Service Price") {

                    charge = charge + item.amount;
                    this.appointmentDetails.servicePrice = item.amount;

                } else if (item.type == "Surcharge") {
                    charge = charge + item.amount;
                } else {
                    charge = charge - item.amount;
                }

            });
            charge = parseFloat(charge / 100).toFixed(2);
            this.appointmentTotalCharge = charge;

            let paidAmount = 0;

            this.appointmentDetails.appointmentPayments.map((item) => {

                paidAmount = paidAmount + item.payment.total_transaction;

            });

            paidAmount = parseFloat(paidAmount / 100).toFixed(2);
            if (charge >= paidAmount) {
                this.appointmentPaymentStatus = "Paid";
            } else if (paidAmount == 0) {
                this.appointmentPaymentStatus = "Unpaid";
            } else {
                this.appointmentPaymentStatus = "Partially paid";
            }

        },

        // appointmentCharges(currentCharges) {

        // }

    },
    computed: {

    },

}
</script>

<style>

</style>
