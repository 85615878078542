<template>
<div class="modal fade" id="AdminNoteCreateModal" tabindex="-1" role="dialog" aria-labelledby="AdminNoteCreateModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Create Note</h1>
                <button @click="resetData()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div>
                    <div class="row ">
                        <div class="col-12 form-group" v-if="userAllType && userAllType.length > 1">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userAllType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.user_type !== undefined && errors.user_type.length > 0">
                                <p v-for="(error,index) in  errors.user_type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>Note Type</label>
                            <VueMultiselect v-model="selectedAppointmentNoteType" class="" :allow-empty="false" :options="appointmentAllNoteType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="errors.type !== undefined && errors.type.length > 0">
                                <p v-for="(error,index) in  errors.type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea v-model="description" class="form-control" name="" id="" rows="10"></textarea>
                                <div v-if="errors.description !== undefined && errors.description.length > 0">
                                    <p v-for="(error,index) in  errors.description" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Visible Note to customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="visibleNoteToCustomer" type="checkbox" class="custom-control-input" checked id="visibleNoteToCustomer">
                                                <label class="custom-control-label mr-1" for="visibleNoteToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ visibleNoteToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="visibleNoteToCustomer">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Notify customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="notifyToCustomer" type="checkbox" class="custom-control-input" checked id="notifyToCustomer">
                                                <label class="custom-control-label mr-1" for="notifyToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ notifyToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" @click="createNote" class="btn btn-primary">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Create</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions, mapGetters,

} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
    name: "AdminNoteCreate",
    mixins: [ShowToastMessage, Loader],
    props: ['userAllType', 'selectedAppointmentId', 'selectedUserId', 'appointmentAllNoteType'],
    data() {
        return {
            visibleNoteToCustomer: false,
            notifyToCustomer: false,
            description: '',
            selectedUserType: '',
            selectedAppointmentNoteType: '',
            errors: {},
            frontEndErrors: {
                userType: [],
                noteType: [],
                noteDescription: [],

            }
        };
    },
    computed: {
        ...mapGetters({
    
            appointmentNote: 'appAppointmentNotes/appointmentNote',
    }),

    },
    watch: {

    visibleNoteToCustomer(visibleNoteToCustomer){
        if (!visibleNoteToCustomer) {
            this.notifyToCustomer = visibleNoteToCustomer;
        }
        
    } ,     
     
     userAllType(currentAllUserTypes){
         if (currentAllUserTypes.length == 1) {
             this.selectedUserType = this.userAllType[0];
         }
         
     }

    },
    methods: {
        ...mapActions({
            postAppointmentNotes: 'appAppointmentNotes/postAppointmentNotes',
            postAppointmentNoteCreationNotifyEmail: 'appAppointmentNotes/postAppointmentNoteCreationNotifyEmail',

        }),
        async createNote() {
            this.loader(true);
            const data = {
                appointment_id: this.selectedAppointmentId,
                user_id:  this.$store.getters["appAuthLogin/authUser"].id,
                user_type: this.selectedUserType.value ?? '',
                type: this.selectedAppointmentNoteType.value ?? '',
                description: this.description,
                customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

            }

            const response = await this.postAppointmentNotes(data);
            if (response.status === 200 || response.status === 201) {
               
                this.loader(false);
                document.querySelector('[data-target="#AdminNoteCreateModal"]').click();
                await this.appointmentNoteCreateEmailNotify();
                this.resetData();
                this.showToastMessage(response.message);
            } else {
                 this.loader(false);
                this.errors = response.errors;
            }
            if (response.message) {
              
                this.showToastMessage(response);
            }

        },
       async appointmentNoteCreateEmailNotify() {
            const data = {
                id: this.appointmentNote.id,
                params: {
                  
                    notify_internal_user: 1,
                    notify_technician: 1,
                    notify_creator: 1,
                    notify_customer: this.notifyToCustomer && this.notifyToCustomer ? 1 : 0
                }

            };
          this.loader(true);
           await this.postAppointmentNoteCreationNotifyEmail(data);
           this.loader(false);
        },
        resetData() {
            this.visibleNoteToCustomer = false;
            this.selectedAppointmentNoteType = "";
            this.description = "";
            this.errors = {}
        }
    },
   

};
</script>

<style>
</style>
