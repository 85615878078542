<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {mapActions, mapGetters} from "vuex";
import ConfirmDialogue from "@/components/backEnd/modal/ConfirmDialogue.vue";

export default {
  name: "AssignPoolTechnicianModal",
  components: {ConfirmDialogue},
  mixins: [ShowToastMessage, Loader],
  props: ['appointment', 'appointmentParams'],
  emits: ['getAppointmentList'],
  data() {
    return {
      selectedTechnician: '',
      frontendErrors: {
        selectedTechnician: [],
        selectedOption: [],
      },
      radioValue: 1,
      notifyToTechnician: false,
      selectedOption: {
        value: '',
        name: "Any",
      },
      multipleStatus: false,
      pushChannel: '',
    }
  },
  computed: {
    ...mapGetters({
      poolOfTechnicians: 'appPoolOfTechnicians/poolOfTechnicians',
    }),

    activePoolOfTechniciansWithState() {
      return this.poolOfTechnicians?.map((poolTechnician) => ({
        name: `${poolTechnician?.user?.full_name} - ${poolTechnician.user.addresses?.[0]?.state?.toUpperCase() ?? ''}`,
        id: poolTechnician.id,
      })) ?? [];
    },

    technicianStatus() {
      if (this.appointment.technicianAppointment == null) {
        return 'Not Assigned';
      }
      if (this.appointment.technicianAppointment.technician.poolOfTechnician == null) {
        return '(Internal)';
      }
      if (this.appointment.technicianAppointment.technician.poolOfTechnician.id) {
        return '(Pool)';
      }
      return '';
    },

    optionList() {
      return [
        {
          name: "Individual/Selected Push",
          value: 1,
        },
        {
          name: "Individual Assign",
          value: 2,
        }
      ]
    },

    assignedPoolTechnician() {
      let poolTechId = this.appointment?.technicianAppointment?.technician?.poolOfTechnician?.id ?? null;
      if (poolTechId) {
        return {
          id: this.appointment.technicianAppointment.technician.id,
          name: '',
        }
      }
      return null;
    },

    isButtonDisabled() {
      return this.appointment.push_channel !== 'Assigned';
    }

  },
  watch: {
    appointment() {
      this.selectedTechnician = '';
      this.frontendErrors.selectedTechnician = [];
    },

    'appointment.push_channel': function (newValue) {
      this.pushChannel = newValue;
    },
    selectedTechnician() {
      this.frontendErrors.selectedTechnician = [];
    },
    selectedOption(selectedOption) {
      this.selectedTechnician = '';
      this.multipleStatus = selectedOption.value === 1;
    }

  },
  methods: {
    ...mapActions({
      getPoolOfTechnicians: 'appPoolOfTechnicians/getPoolOfTechnicians',
      addPoolTechToAppointment: 'appPoolOfTechnicians/addPoolTechnicianToAppointment',
      postAppointmentPushNotification: 'appAppointments/postAppointmentPushNotification',
      postAppointmentCreationNotifyEmail: 'appAppointments/postAppointmentCreationNotifyEmail',
      removePoolTechnicianFromAppointment: 'appPoolOfTechnicians/removePoolTechnicianFromAppointment'
    }),

    async createAppointmentTechnician() {
      const techId = await this.poolOfTechnicians.find((poolTech) => poolTech.id == this.selectedTechnician.id)?.technician?.id;
      const dataObj = {
        id: this.selectedTechnician.id !== undefined ? this.selectedTechnician.id : '',
        data: {
          technician_id: techId ?? '',
          appointment_id: this.appointment.id,
          panel: 1,
          user_id: this.$store.getters["appAuthLogin/authUser"].id,
        }

      };
      this.loader(true);
      const response = await this.addPoolTechToAppointment(dataObj);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {
        this.showToastMessage({
          message: response.message,
          type: 'success'
        });
      }
    },

    async sendPushChannel() {
      let pool_technician_id = [];
      if (this.selectedOption.value === 1) {
        pool_technician_id = this.selectedTechnician.map((pool) => pool.id);
      }
      const data = {
        id: this.appointment.id,
        notify_all: this.selectedOption.value === 1 ? 0 : 1,
        pool_technician_id: pool_technician_id
      };
      this.loader(true);
      const response = await this.postAppointmentPushNotification(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        const toastObj = {
          message: response?.data?.message ?? 'Push to Pools Successful',
          type: 'success'
        };
        this.showToastMessage(toastObj);
      } else {
        this.showToastMessage(response);
      }
    },

    checkDataFiled() {
      if (this.radioValue == 2 && this.selectedOption.value === '') {
        this.showToastMessage({
          message: 'Please select a option',
          type: 'error'
        });
        return false;
      }
      if (this.radioValue == 2 && this.selectedTechnician === '') {
        this.showToastMessage({
          message: 'Please select pool technician',
          type: 'error'
        });
        return false;
      }
      return true
    },

    async openAppointmentPushChannelModal() {
      if (!this.checkDataFiled()) {
        return;
      }
      this.$refs.confirmDialogue.show({
        title: 'Confirm',
        message: 'Do you want to send this appointment to pools, if yes press YES button otherwise NO',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then(async (confirmResponse) => {
        if (confirmResponse === true) {
          await this.sendPushChannel();
          this.$emit('getAppointmentList', this.appointmentParams);
          document.querySelector('[data-target="#assignPoolTechnicianModal"]').click();
          this.resetData();
        }
      });
    },

    async removePoolTech(appointmentId, technicianId) {
      const data = {
        appointment_id: appointmentId,
        user_id: this.$store.getters["appAuthLogin/authUser"].id,
        technician_id: technicianId,
      };
      this.loader(true);
      const response = await this.removePoolTechnicianFromAppointment(data);
      console.log(response);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        const toastObj = {
          message: response?.data?.message ?? 'Pool Technician remove from this appointment',
          type: 'success'
        };
        this.showToastMessage(toastObj);
      } else {
        this.showToastMessage(response);
      }
    },

    async cancelPoolTechnicianModal() {
      let appointmentId = this.appointment.id;
      let technicianId = this.appointment?.technicianAppointment?.technician?.id ?? null;
      this.$refs.confirmDialogue.show({
        title: 'Confirm',
        message: 'Are you sure to cancel this appointment from pool of technician, if yes press YES button otherwise NO',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then(async (confirmResponse) => {
        if (confirmResponse === true) {
          await this.removePoolTech(appointmentId, technicianId);
          this.$emit('getAppointmentList', this.appointmentParams);
          document.querySelector('[data-target="#assignPoolTechnicianModal"]').click();
          this.resetData();
        }
      });
    },

    async assignPoolTechAppointment() {
      if (!this.checkDataFiled()) {
        return;
      }
      await this.createAppointmentTechnician();
      this.$emit('getAppointmentList', this.appointmentParams);
      if (this.radioValue == 2 && this.notifyToTechnician) {
        this.appointmentEmailNotifyTechnician();
      }
      document.querySelector('[data-target="#assignPoolTechnicianModal"]').click();
      this.resetData();
    },

    appointmentEmailNotifyTechnician() {
      const data = {
        appointment: this.appointment.id,
        params: {
          notify_customer: 0,
          notify_technician: 1,
          notify_internal_user: 1
        }

      };
      this.postAppointmentCreationNotifyEmail(data);
    },

    pushChanelStatusBadgeColour(status) {
      if (status === 'Active')
        return 'badge-light-warning';
      else if (status === 'Assigned')
        return 'badge-light-danger';
      else if (status === 'Cancel')
        return 'badge-light-info';
      else
        return 'badge-light-secondary';
    },

    resetData() {
      this.radioValue = 1;
      this.selectedTechnician = '';
      this.notifyToTechnician = false;
      this.selectedOption.value = '';
      this.selectedOption = {
        value: '',
        name: 'Any',
      };
    }

  },

  async mounted() {
    await this.getPoolOfTechnicians({
      status: 1,
      with_relation: [
        'technician',
        'user.addresses'
      ]
    });
  }
}
</script>

<template>
  <div class="modal fade " id="assignPoolTechnicianModal" tabindex="-1" role="dialog"
       aria-labelledby="assignPoolTechnicianModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            <div>
              REF: {{ appointment.reference }}
            </div>
            <div> STATUS: {{ appointment.status }}</div>
          </h1>
          <div class="modal-title white">
            <div>
              {{ appointment?.technicianAppointment?.technician?.user?.full_name }} <span>{{ technicianStatus }}</span>
            </div>
          </div>
          <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset class="col-8" :disabled="!isButtonDisabled">
              <div class="col-12">
                <p>Please select push gateway</p>
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block mr-2 mb-1">
                    <fieldset>
                      <div class="radio radio-success radio-glow">
                        <input type="radio" id="radioGlow3" name="radioGlow" v-model="radioValue" value="1">
                        <label for="radioGlow3">All Pool</label>
                      </div>
                    </fieldset>
                  </li>
                  <li class="d-inline-block mr-2 mb-1">
                    <fieldset>
                      <div class="radio radio-danger radio-glow">
                        <input type="radio" id="radioGlow4" name="radioGlow" v-model="radioValue" value="2">
                        <label for="radioGlow4">Individual</label>
                      </div>
                    </fieldset>
                  </li>
                </ul>
              </div>
              <div v-if="(poolOfTechnicians !== undefined &&  poolOfTechnicians.length > 0) && radioValue == 2">
                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Select Option</label>
                      <VueMultiselect v-model="selectedOption" :allow-empty="false" class="multiselect-blue"
                                      :options="optionList" :close-on-select="true"
                                      placeholder="Select a option" label="name" track-by="value"
                                      :show-labels="false"/>
                    </div>

                    <div v-if="frontendErrors.selectedOption.length > 0 " class="">
                      <p class="text-danger mb-0 font-medium-1"
                         v-for="(error, index) in frontendErrors.selectedOption"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Select Pool Technician</label>
                      <VueMultiselect v-model="selectedTechnician"
                                      :multiple="multipleStatus"
                                      :allow-empty="false"
                                      :options="activePoolOfTechniciansWithState"
                                      :close-on-select="true"
                                      :show-labels="false"
                                      class="multiselect-blue"
                                      placeholder="Select a pool technician"
                                      label="name"
                                      track-by="id"
                      ></VueMultiselect>
                    </div>

                    <div v-if="frontendErrors.selectedTechnician.length > 0 " class="">
                      <p class="text-danger mb-0 font-medium-1"
                         v-for="(error, index) in frontendErrors.selectedTechnician"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="true">
                  <div class="form-group">
                    <div class="controls">
                      <label>Notify Pool Technician</label>
                      <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="notifyToTechnician" type="checkbox" class="custom-control-input" checked
                                   id="notifyToPoolTechnician">
                            <label class="custom-control-label mr-1" for="notifyToPoolTechnician"></label>
                          </div>
                          <span class="font-medium-1">{{ notifyToTechnician ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="col-4">
              <div class="col-12">
                <p>Push:
                  <span class="badge badge-pill"
                        :class="pushChanelStatusBadgeColour(pushChannel)">
                        {{ pushChannel ?? 'Open' }}
                      </span>
                </p>

                <div v-if="pushChannel == 'Assigned'">
                  <p><i class='bx bxs-wrench text-danger'></i>
                    {{ appointment?.technicianAppointment?.technician?.user?.full_name }}
                    <span>{{ technicianStatus }}</span>
                    <button @click="cancelPoolTechnicianModal" type="button" class="btn btn-sm btn-danger round">
                      Cancel
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" v-if="radioValue == 1"
                  @click="openAppointmentPushChannelModal()" :disabled="!isButtonDisabled">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Push to pool</span>
          </button>
          <button type="button" class="btn btn-primary" v-if="radioValue == 2"
                  @click=" selectedOption.value == 1 ? openAppointmentPushChannelModal(): assignPoolTechAppointment()">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{ selectedOption.value == 1 ? 'Send Push' : 'Assign Tech' }}</span>
          </button>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>
<style scoped>

</style>