<template>
  <div class="modal fade " id="assignAdminTaskModal" tabindex="-1" role="dialog"
       aria-labelledby="assignAdminTaskModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">{{ adminTaskId === null ? 'Add' : 'Update' }} Admin Task -
            {{ appointment?.reference ?? '' }}</h1>
          <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row"
                 v-if="(availableAdmin !== undefined &&  availableAdmin.length > 0)">
              <div class="col-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Title</label>
                    <input type="text" v-model="title" name="title" id="title" class="form-control"
                           placeholder="Enter title" required="required">
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <VueMultiselect v-model="selectedStatus" :allow-empty="false" class="multiselect-blue"
                                    :options="statusOptions" :close-on-select="true"
                                    placeholder="Select a status" label="name" track-by="value" :show-labels="false"/>
                  </div>
                  <div v-if="frontendErrors.selectedStatus.length > 0 " class="">
                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedStatus"
                       :key="index">{{ error }}</p>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="startTime" :update-on-input="true"
                                  mode="dateTime"
                                  :min-date="new Date()"
                                  :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"
                        >
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()"
                               style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="endTime" :update-on-input="true"
                                  mode="dateTime"
                                  :min-date="new Date()"
                                  :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"
                        >
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()"
                               style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select Admin</label>
                    <VueMultiselect v-model="selectedAdmin" :allow-empty="false"
                                    class="multiselect-blue"
                                    :options="adminOptions"
                                    :close-on-select="true" placeholder="Select a admin"
                                    label="name" track-by="value" :show-labels="false"/>
                  </div>

                  <div v-if="frontendErrors.selectedAdmin.length > 0 " class="">
                    <p class="text-danger mb-0 font-medium-1"
                       v-for="(error, index) in frontendErrors.selectedAdmin" :key="index">
                      {{ error }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea class="form-control"
                            placeholder="Please provide description, if necessary"
                            v-model="description">
                      </textarea>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button :disabled="false" type="button" class="btn btn-primary"
                  @click="adminTaskId !== null ? updateAdminTaskInfo() : createAdminTaskInfo()">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{ adminTaskId !== null ? "Update Changes" : "Save Changes" }}</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from "v-calendar";

import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "AssignAdminModal",
  components: {
    DatePicker
  },
  mixins: [ShowToastMessage, Loader],
  emits: ['updatedAdminTask'],
  props: {
    appointment: {
      required: true,
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      title: '',
      selectedAdmin: {
        value: '',
        name: 'None',
      },
      frontendErrors: {
        selectedAdmin: [],
        selectedStatus: [],
      },
      date: new Date(),
      startTime: '',
      endTime: '',
      description: '',
      isSelectedAdminChange: false,
      selectedStatus: {
        value: '',
        name: 'None',
      },
      adminTaskHistoryDetails: {},
    }
  },

  computed: {
    ...mapGetters({
      availableAdmin: 'appAdmins/admins',
      authUser: "appAuthLogin/authUser",
      adminTask: 'appAdminTasks/adminTask',
    }),
    statusOptions() {
      return [
        {
          name: 'None',
          value: ''
        },
        {
          name: 'In Progress',
          value: 0
        },
        {
          name: 'Processing',
          value: 1
        },
        {
          name: 'Completed',
          value: 2
        },
        {
          name: 'Closed',
          value: 3
        },
      ]
    },
    owner() {
      let ownerObj = this.appointment?.appointmentCreator?.user ?? null;
      return {
        name: ownerObj?.full_name,
        id: ownerObj?.id
      }
    },

    adminOptions() {
      return (this.availableAdmin ?? []).map((admin) => ({
        value: admin.user.id,
        name: `${admin.user.full_name} ${(this.owner.id === admin.user.id) ? '   (owner)' : ''}`,
      }));
    },
    adminTaskId() {
      return this.appointment.adminTask?.id ?? null;
    },

  },
  watch: {
    appointment() {
      this.selectedAdmin.value = '';
      this.selectedAdmin.name = 'None';
      this.frontendErrors.selectedAdmin = [];

    },

    adminTaskId(newValue) {
      if (newValue != null) {
        this.getAdminTaskInfo(newValue)
      }
    },
    selectedAdmin(newValue) {
      this.isSelectedAdminChange = (this?.adminTask?.assignedTo?.id !== newValue.value) && newValue.value !== '';
    }

  },
  methods: {
    ...mapActions({
      getAvailableAdmin: 'appAdmins/getAdmins',
      getAdminTask: 'appAdminTasks/getAdminTask',
      postAdminTask: 'appAdminTasks/postAdminTask',
      putAdminTask: 'appAdminTasks/putAdminTask',
      postAdminTaskHistory: 'appAdminTaskHistories/postAdminTaskHistory',
    }),
    async getAdminTaskInfo(adminTaskId) {
      let paramObj = {
        id: adminTaskId,
        params: {
          with_relation: [
            'assignedTo', 'creator'
          ]
        },
      };

      await this.getAdminTask(paramObj).then(async (response) => {
        if (response.status === 200) {
          const findAssignedUser = this.adminOptions.find(({value}) => value === this.adminTask.assignedTo.id);
          if (findAssignedUser !== undefined) {
            this.selectedAdmin.value = findAssignedUser.value
            this.selectedAdmin.name = findAssignedUser.name
          }
          this.date = this.adminTask.date;
          this.startTime = this.adminTask.start_time;
          this.endTime  = this.adminTask.end_time;
          this.description = this.adminTask.description;
          this.title = this.adminTask.title;
          this.selectedStatus = this.statusOptions?.find(({name}) => name == this.adminTask.status)?? this.selectedStatus;
        }
      });
    },

    async createAdminTaskHistory(adminTaskId, details) {
      const data = {
        user_id: this.$store.getters["appAuthLogin/authUser"].id,
        assigned_to: this.selectedAdmin.value,
        admin_task_id: adminTaskId,
        status: this.selectedStatus.value,
        details: JSON.stringify(details),
      };
      this.loader(true);
      const response = await this.postAdminTaskHistory(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {

        this.showToastMessage({
          message: 'Admin task history created',
          type: 'success'
        });
        //document.querySelector('[data-target="#assignAdminTaskModal"]').click();
      }
    },
    // call store admin task
    async createAdminTask() {
      const createDataObj = {
        title: this.title,
        assigned_to: this.selectedAdmin.value,
        creator_id: this.$store.getters["appAuthLogin/authUser"].id,
        start_time: this.startTime,
        end_time: this.endTime,
        appointment_id: this.appointment.id,
        description: this.description,
        status: this.selectedStatus.value,

      };
      this.adminTaskHistoryDetails = createDataObj;
      this.loader(true);
      const response = await this.postAdminTask(createDataObj);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {
        this.showToastMessage({
          message: 'Admin Task created',
          type: 'success'
        });


      }
    },
    // call update admin task
    async updateAdminTask() {
      if (!this.selectedAdmin?.value && this.isSelectedAdminChange) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select assignee'
        });
        return;
      }

      const updateDataObj = {
        id: this.adminTaskId,
        data: {
          assigned_to: this.selectedAdmin.value,
          creator_id: this.$store.getters["appAuthLogin/authUser"].id,
          start_time: this.startTime,
          end_time: this.endTime,
          appointment_id: this.appointment.id,
          description: this.description,
          status: this.selectedStatus.value,
        }
      }
      this.adminTaskHistoryDetails = updateDataObj.data;
      this.loader(true);
      const response = await this.putAdminTask(updateDataObj);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200) {
        this.showToastMessage({
          type: 'success',
          message: 'Updated successfully'
        });
        this.$emit('updatedAdminTask', this.adminTask);

        //this.resetData();
      }

    },
    // check selected admin & selected status value
    async checkValidation() {
      this.frontendErrors.selectedAdmin = [];
      this.frontendErrors.selectedStatus = [];
      if (this.selectedStatus.value === '') {
        this.frontendErrors.selectedStatus.push('Please select a status');
        return;
      }
      if (this.selectedAdmin.value === '') {
        this.frontendErrors.selectedStatus.push('Please select a admin');
      }
    },
    // create admin task request
    async createAdminTaskInfo() {
      await this.checkValidation();
      await this.createAdminTask();
      await this.createAdminTaskHistory(this.adminTask.id, this.adminTaskHistoryDetails);
      this.closeModal();
    },
    // update admin task request
    async updateAdminTaskInfo() {
      await this.checkValidation();
      await this.updateAdminTask();
      await this.createAdminTaskHistory(this.adminTaskId, this.adminTaskHistoryDetails);
      this.closeModal();
    },
    // reset all data property object default value
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    closeModal() {
      document.querySelector('[data-target="#assignAdminTaskModal"]').click();
    }

  },
  async mounted() {
    await this.getAvailableAdmin({
      status: 1,
      with_relation: ['user']
    })
  }

}
</script>

<style>
</style>
