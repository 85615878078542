<template>
<div class="modal fade " id="appointmentHistoryModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Appointment History</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row pt-1">
                        <div class="col-12">
                            <div class="table-responsive" v-if="appointmentHistories.length > 0">
                                <table class="table table-nowrap card-table">
                                    <thead class="thead-light">

                                        <tr>
                                            <th>REF. ID </th>
                                            <th>TECHNICIAN</th>
                                            <th>AUTHOR</th>
                                            <th>STATUS</th>
                                            <th>PLATFORM</th>
                                            <th>DATE</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(appointmentHistory,index) in appointmentHistories" :key="index">
                                            <td>{{ appointmentHistory.appointment.reference }}</td>
                                            <td>{{  appointmentHistory?.technician?.id ?  `${appointmentHistory.technician.user.first_name} ${appointmentHistory.technician.user.last_name}` :  'Not Assigned' }}</td>
                                            <td>{{ `${appointmentHistory.user.first_name} ${appointmentHistory.user.last_name}` }}</td>
                                            <td>

                                                <span v-if="appointmentHistory.status === 'Open'" class="badge badge-light-warning badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Cancelled'" class="badge badge-light-danger badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Closed'" class="badge badge-light-success badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Credited'" class="badge badge-light-primary badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Delivered'" class="badge badge-light-success badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Dispute'" class="badge badge-light-danger badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Hold'" class="badge badge-light-danger badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Reassigned'" class="badge badge-light-info badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Refund'" class="badge badge-light-secondary badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else-if="appointmentHistory.status === 'Rescheduled'" class="badge badge-light-info badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                                <span v-else class="badge badge-primary badge-pill text-capitalize">
                                                    {{
                                                        appointmentHistory.status
                                                    }}
                                                </span>
                                            </td>
                                            <td>{{appointmentHistory.panel}}</td>
                                            <td> {{
                                                            new Date(`${appointmentHistory.created_at}`).toLocaleDateString("en-GB", {
                                                                    day: "numeric",
                                                                    month: "short",
                                                                    year: "numeric",

                                                            })
                                                }} 

                                            </td>
                                        </tr>

                                    </tbody>
                                    <!-- <tfoot>
                                        <tr>
                                            <td colspan="3" class="border-0 text-right pb-0">GST</td>
                                            <td class="border-0 text-right pb-0">$15.6</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="border-0 text-right pb-0">Total price</td>
                                            <td class="border-0 text-right pb-0">$171.60</td>
                                        </tr>
                                    </tfoot> -->
                                </table>
                            </div>
                            <div v-else>
                                <h1 class="text-center py-2 text-secondary mb-0">No History Found !</h1>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapGetters
} from "vuex";

export default {
    name: "AppointmentHistoryModal",
    computed: {
        ...mapGetters({
            appointmentHistories: "appAppointments/appointmentHistories",

        }),

    },

}
</script>

<style>

</style>
