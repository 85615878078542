<template>
  <div class="modal fade" id="addDiscountModal" tabindex="-1" role="dialog" aria-labelledby="addDiscountModal"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Add Discount ({{ appointment?.reference ?? "No Ref. Found" }})</h1>
          <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Type</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="adjustmentDiscountType" type="checkbox" class="custom-control-input" checked
                                 id="appliedDiscount">
                          <label class="custom-control-label mr-1" for="appliedDiscount"></label>
                        </div>
                        <span class="font-medium-1">
                          {{ adjustmentDiscountType ? "Adjustment Discount" : "Applied Discount" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="adjustmentDiscountType" class="col-12">
                <div v-if="!adjustableDiscountTimeSlotCount" class="text-danger">No discountable time slot is available
                  to apply discount
                </div>
                <div v-else>
                  <div v-for="index in adjustableDiscountTimeSlotCount" :key="index"
                       class="form-check form-check-inline">
                    <input v-model="adjustableDiscountTimeSlotSelectCounter"
                           :value="index" class="form-check-input"
                           type="checkbox" :id="'adjustmentDiscountTimeSlot-'+index">
                    <label class="form-check-label" :for="'adjustmentDiscountTimeSlot-'+index">30 Mins</label>
                  </div>
                  <div class="mt-1">Discount Amount: ${{ (adjustableDiscountAmount / 100).toFixed(2) }}</div>
                </div>
              </div>
              <div v-else class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Amount</label>
                    <input v-model="appliedDiscountAmount" type="number" class="form-control">
                    <small>Maximum Discountable Amount: ${{ appliedDiscountMaximumAmount.toFixed(2) }}</small>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button :disabled="isDiscountButtonDisabled" type="button" class="btn btn-primary" @click="addDiscount()">
            <span>Save Changes</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "AddDiscountModal",
  mixins: [ShowToastMessage, Loader],
  props: ['appointment', 'appointmentParams'],

  data() {
    return {
      appointmentUnitPrice: 0,
      appointmentLength: '',
      appointmentReference: '',
      appointmentId: null,
      appointmentUserId: null,

      adjustmentDiscountType: true, // if false, then considered as applied discount.
      adjustableDiscountTimeSlotCount: 0,
      adjustableDiscountTimeSlotSelectCounter: [],
      adjustableDiscountAmount: 0,

      appliedDiscountAmount: 0,
      appliedDiscountMaximumAmount: 0,

    }
  },
  computed: {
    ...mapGetters({
      availableTechnicians: 'appTechnicians/availableTechnicians',
    }),
    isDiscountButtonDisabled() {
      if (!this.adjustmentDiscountType) {
        return !(this.appointmentReference && this.appointmentId && this.appointmentUserId && this.appliedDiscountAmount && !(this.appliedDiscountAmount > this.appliedDiscountMaximumAmount));
      }
      return !(this.appointmentReference && this.appointmentId && this.appointmentUserId && this.adjustableDiscountAmount);
    },
  },
  watch: {
    appointment(currentAppointment) {
      if (currentAppointment?.reference) {
        this.appointmentReference = currentAppointment?.reference ?? null;
        this.appointmentId = currentAppointment?.id ?? null;
        this.appointmentUserId = currentAppointment?.customer?.user?.id ?? null;
        this.adjustmentDiscountType = currentAppointment?.status === 'Delivered';

        this.appointmentUnitPrice = currentAppointment?.unit_price; // unit price is in cents.
        this.appointmentLength = currentAppointment?.length;

        let appointmentLengthSplit = this.appointmentLength.split(":");
        this.adjustableDiscountTimeSlotCount = (appointmentLengthSplit[0] > 0 ? appointmentLengthSplit[0] - 1 : 0) * 2 + (appointmentLengthSplit[1] > 0 && appointmentLengthSplit[1] <= 30 ? 1 : 0) + (appointmentLengthSplit[1] > 30 ? 1 : 0)

        let ServicePriceAmount = 0;
        this.appointment.appointmentCharges.map(appointmentCharge => {
          if (appointmentCharge.type === "Service Price")
            ServicePriceAmount += appointmentCharge.amount;
        });
        this.appliedDiscountMaximumAmount =  (ServicePriceAmount - this.appointmentUnitPrice) / 100;
      }
    },


    adjustableDiscountTimeSlotSelectCounter(adjustableDiscountTimeSlotSelectCounter) {
      this.adjustableDiscountAmount = this.appointmentUnitPrice * (.5 * adjustableDiscountTimeSlotSelectCounter.length)
    }

  },
  methods: {
    ...mapActions({
      postAppointmentChargesDiscount: 'appAppointments/postAppointmentChargesDiscount',
      postDiscountStoreList: 'appDiscounts/postDiscountStoreList',

    }),


    resetData() {
      this.appointmentUnitPrice = 0;
      this.appointmentLength = '';
      this.appointmentReference = '';
      this.appointmentId = null;
      this.appointmentUserId = null;
      this.adjustmentDiscountType = true;
      this.adjustableDiscountTimeSlotCount = 0;
      this.adjustableDiscountTimeSlotSelectCounter = [];
      this.adjustableDiscountAmount = 0;
      this.appliedDiscountAmount = 0;
      this.appliedDiscountMaximumAmount = 0;
    },

    async addDiscount() {
      this.loader(true);
      const response = await this.postAppointmentChargesDiscount({
        id: this.appointmentId,
        data: {
          name: this.adjustmentDiscountType ? "Adjustment Discount" : "Applied Discount",
          amount: this.adjustmentDiscountType ? this.adjustableDiscountAmount : this.appliedDiscountAmount * 100,
        }
      });
      this.loader(false);
      if (response.status === 200) {
        document.querySelector('[data-target="#addDiscountModal"]').click();
        this.createAppointmentDiscountStoreList();
        this.$emit('getAppointmentList', this.appointmentParams);
        this.resetData();
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },

    createAppointmentDiscountStoreList() {
      /*
        0:Credited Payment,
        1:Applied on Subtotal,
        2:Applied on Shipping Fee,
        3:Coupon on Subtotal,
        4:Coupon on Shipping Fee,
        5:Parking,
        6:Online Appointment
        */

      const dataObj = {
        user_id: this.appointmentUserId,
        reference: this.appointmentReference,
        discounts: [{
          amount: this.adjustmentDiscountType ? this.adjustableDiscountAmount : this.appliedDiscountAmount * 100,
          type: 1,
        }]
      }
      this.postDiscountStoreList(dataObj);
    },

  }

}
</script>
