<template>
<div class="modal fade " id="AppointmentStatusUpdateModal" tabindex="-1" role="dialog" aria-labelledby="AppointmentStatusUpdateModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Choose Status</h1>
                <div class="modal-title white" v-if="appointment?.status">
                  Current Status:
                  <span class="badge badge-pill badge-light-default"> {{ appointment?.status }} </span>
                </div>
                <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>

                    <div class="row">
                        <div class="col-12 ">
                            <h6 class="text-danger"><i>{{statusPreUpdateChargeResponse.message ? statusPreUpdateChargeResponse.message : '' }}</i></h6>
                            <ul class="pl-1">
                                <li class="" v-for="(value, name,index) in statusPreUpdateChargeResponse.details" :key="index">
                                    <span class="text-capitalize"> {{name.replaceAll("_", " ")}} </span> : $ {{ value}}
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Appointment Status</label>
                                    <VueMultiselect v-model="selectedStatus" :allow-empty="false" class="multiselect-blue" :options="allAppointmentStatus" :close-on-select="true" placeholder="Select a status" label="name" track-by="value" :show-labels="false" />
                                </div>
                                <div v-if="frontendErrors.selectedStatus.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedStatus" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="selectedStatus.value == 1">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Cancellation Penalty Type</label>
                                    <VueMultiselect v-model="selectedPenaltyType" :allow-empty="false" class="multiselect-blue" :options="penaltyTypes" :close-on-select="true" placeholder="Select a type" label="name" track-by="value" :show-labels="false" />
                                </div>
                                <div v-if="frontendErrors.selectedPenaltyType.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedPenaltyType" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row " v-if="selectedStatus.value == 9 || selectedStatus.value == 10 ">

        
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Select Date</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="w-100 ">
                                        <DatePicker v-model="selectedRescheduleDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                            <template v-slot="{ inputValue ,togglePopover  }">
                                                <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                    <i class='bx bx-calendar mr-1'></i>
                                                    <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                </div>
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                                <div v-if="frontendErrors.selectedRescheduleDate.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedRescheduleDate" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6" v-if="(selectedStatus.value == 9 || selectedStatus.value == 10) && (selectedRescheduleDate) && (technicianAvailableTimeSlots.slots !== undefined &&  technicianAvailableTimeSlots.slots.length > 0)">
                            <div class="form-group">
                                <label>Available time </label>
                                <VueMultiselect v-model="selectedRescheduleTime" :allow-empty="false" class="multiselect-blue" :options="technicianAvailableTimeSlots.slots" :close-on-select="true" placeholder="Select a status" label="name" track-by="value" :show-labels="false" />
                                <div v-if="frontendErrors.selectedRescheduleTime.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedRescheduleTime" :key="index">{{error}}</p>
                                </div>
                            </div>

                        </div>
                       
                            
                        
                    </div>

                    <div class="row" v-if="(selectedStatus.value == 7 || (selectedStatus.value == 10 && selectedRescheduleTime.value !== undefined && selectedRescheduleDate))   && (availableTechnicians.technicians !== undefined &&  availableTechnicians.technicians.length > 0)">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Select Technician</label>
                                    <VueMultiselect v-model="selectedTechnician" :allow-empty="false" class="multiselect-blue" :options="availableTechniciansWithDistanceAndDuration" :close-on-select="true" placeholder="Select a status" label="name" track-by="id" :show-labels="false" />
                                </div>

                                <div v-if="frontendErrors.selectedTechnician.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedTechnician" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="selectedStatus.value == 4">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Length</label>
                                    <input type="text" v-model="length" class="form-control" placeholder="Enter length (Example :  01:30)" />

                                </div>

                                <div v-if="frontendErrors.length.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.length" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="statusPreUpdateChargeResponse?.extra_duration_discountable" class="col-12">
                            <div class="form-group row">
                                <div class="controls col-10">
                                    <label>Discount Amount (max amount ${{ statusPreUpdateChargeResponse.extra_duration_discountable }} )</label>
                                    <input type="number" v-model="extraDurationDiscountableAmount" class="form-control" placeholder="Enter amount" />
                                </div>
                                <div class="controls col-2">
                                    <label style="visibility: hidden;">dummy</label>
                                    <button @click="applyExtraDurationDiscountableAmount()" class="btn btn-primary form-control"> Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <div class="row" v-if="selectedStatus.value == 9 || selectedStatus.value == 10">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Reschedule requested by customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="rescheduleRequestedByCustomer" type="checkbox" class="custom-control-input" checked id="isRescheduledByCustomer">
                                                <label class="custom-control-label mr-1" for="isRescheduledByCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ rescheduleRequestedByCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="selectedStatus.value == 1">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Cancellation requested by customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="cancellationRequestedByCustomer" type="checkbox" class="custom-control-input" checked id="isRescheduledByCustomer">
                                                <label class="custom-control-label mr-1" for="isRescheduledByCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ cancellationRequestedByCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="selectedStatus">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Add Note</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="haveANote" type="checkbox" class="custom-control-input" checked id="haveAppointmentNote">
                                                <label class="custom-control-label mr-1" for="haveAppointmentNote"></label>
                                            </div>
                                            <span class="font-medium-1">{{ haveANote ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="haveANote">

                        <div class="col-12 form-group" v-if="userAllTypes && userAllTypes.length > 1">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="userAllTypes" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            <div v-if="backendErrors.user_type !== undefined && backendErrors.user_type.length > 0">
                                <p v-for="(error,index) in  backendErrors.user_type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                            <div v-if="frontendErrors.selectedUserType.length > 0 " class="">
                                <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.selectedUserType" :key="index">{{error}}</p>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea v-model="noteDescription" class="form-control" name="" id="" rows="10"></textarea>
                                <div v-if="backendErrors.description !== undefined && backendErrors.description.length > 0">
                                    <p v-for="(error,index) in  backendErrors.description" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                                <div v-if="frontendErrors.noteDescription.length > 0 " class="">
                                    <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontendErrors.noteDescription" :key="index">{{error}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="selectedStatus && haveANote">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Visible Note to customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="visibleNoteToCustomer" type="checkbox" class="custom-control-input" checked id="visibleNoteToCustomer">
                                                <label class="custom-control-label mr-1" for="visibleNoteToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ visibleNoteToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="selectedStatus">
                        <div class="col-6">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Email Notify To Customer</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="notifyToCustomer" type="checkbox" class="custom-control-input" checked id="statusUpdateNotifyToCustomer">
                                                <label class="custom-control-label mr-1" for="statusUpdateNotifyToCustomer"></label>
                                            </div>
                                            <span class="font-medium-1">{{ notifyToCustomer ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6" v-if="showPaymentCollection">
                        <div class="form-group">
                          <div class="controls">
                            <label class="badge badge-light badge-light-warning">Payments needs to be collected</label>
                            <div style="margin-top: .5rem">
                              <div class="custom-control custom-switch custom-switch-warning custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="paymentNeedCollectedStatus" type="checkbox" class="custom-control-input" checked id="statusPaymentNeedCollected">
                                  <label class="custom-control-label mr-1" for="statusPaymentNeedCollected">
                                    <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                                    <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                                  </label>
                                </div>
                                <span class="font-medium-1 text-bold-600">{{ paymentNeedCollectedStatus ? "Yes" : "No" }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="isUpdateBtnDisable" type="button" class="btn btn-primary" @click="updateAppointmentStatusHandler">
                    <span class="">Update Status</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    DatePicker
} from 'v-calendar';
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "AppointmentStatusUpdateModal",
    mixins: [ShowToastMessage, Loader],
    props: ['appointment', 'appointmentParams', 'userAllTypes'],
    components: {
        DatePicker,
    },
    data() {
        return {
            paymentNeedCollectedStatus: false,
            isExtraDurationDiscountableAmountIsApplied: false,
            extraDurationDiscountableAmount: "",
            updateBtnIsActive: false,
            appointmentId: "",
            appointmentDate: '',
            appointmentTime: '',
            appointmentState: '',
            appointmentPreference: '',
            appointmentReference: '',
            appointmentServiceAddress: {},
            appointmentTechnicianId: "",
            appointmentUserId: "",

            haveANote: false,
            rescheduleRequestedByCustomer: false,
            cancellationRequestedByCustomer: false,
            notifyToCustomer: false,
            visibleNoteToCustomer: false,
            selectedUserType: '',
            noteDescription: '',

            selectedRescheduleDate: '',
            selectedRescheduleTime: '',
            length: '',

            selectedStatus: '',
            selectedTechnician: '',
            selectedPenaltyType: '',

            frontendErrors: {
                selectedStatus: [],
                selectedRescheduleDate: [],
                selectedRescheduleTime: [],
                length: [],
                selectedTechnician: [],
                selectedUserType: [],
                noteDescription: [],
                selectedPenaltyType: [],
            },
            backendErrors: {},
            statusPreUpdateChargeResponse: {}

        }
    },
    watch: {
        appointment(currentAppointment) {

          
            this.updateBtnIsActive = false;
            this.statusPreUpdateChargeResponse = {};

            this.selectedRescheduleDate = '';
            this.selectedRescheduleTime = '';
            this.length = '';

            this.selectedStatus = '';
            this.selectedTechnician = '';
            this.selectedPenaltyType = '';

            this.selectedUserType = "";
            this.haveANote = false;
            this.rescheduleRequestedByCustomer = false;
            this.cancellationRequestedByCustomer = false;
            this.notifyToCustomer = false;
            this.visibleNoteToCustomer = false;
            this.noteDescription = "";

            this.frontendErrors.selectedStatus = [];
            this.frontendErrors.selectedRescheduleDate = [];
            this.frontendErrors.selectedRescheduleTime = [];
            this.frontendErrors.length = [];
            this.frontendErrors.selectedTechnician = [];
            this.frontendErrors.selectedUserType = [];
            this.frontendErrors.noteDescription = [];
            this.frontendErrors.selectedPenaltyType = [];

            this.appointmentId = currentAppointment.id ? currentAppointment.id : '';
            this.appointmentReference = currentAppointment.reference ? currentAppointment.reference : '';
            this.appointmentDate = currentAppointment.date ? currentAppointment.date : '';
            this.appointmentTime = currentAppointment.time ? currentAppointment.time : '';
            this.appointmentPreference = currentAppointment.preference ? (currentAppointment.preference === "Remote" ? 1 : 0) : '';
            this.appointmentState = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
            this.appointmentServiceAddress = currentAppointment ?.address ?? {};
            this.appointmentTechnicianId = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.id ? currentAppointment.technicianAppointment.technician.id : '';
            this.appointmentUserId = currentAppointment ?.customer ?.user ?.id ?? null;

        },

        // userAllTypes(currentUserType){
        //     console.log(currentUserType,'user type fadfasdf')
        //     if ( currentUserType.length > 0) {
        //          this.selectedUserType = this.userAllTypes[0];
        //     }

        // },

        async selectedStatus(currentStatus) {
          
            this.isExtraDurationDiscountableAmountIsApplied = false;
            this.extraDurationDiscountableAmount = "";
            this.updateBtnIsActive = false;
            this.statusPreUpdateChargeResponse = {};

            this.selectedRescheduleDate = "";
            this.selectedRescheduleTime = "";
            this.selectedTechnician = "";
            this.length = "";
            this.selectedUserType = "";
            this.selectedPenaltyType = '';

            this.haveANote = false;
            this.rescheduleRequestedByCustomer = false;
            this.cancellationRequestedByCustomer = false;
            this.notifyToCustomer = false;
            this.visibleNoteToCustomer = false;
            this.noteDescription = "";

            this.frontendErrors.selectedRescheduleDate = [];
            this.frontendErrors.selectedRescheduleTime = [];
            this.frontendErrors.length = [];
            this.frontendErrors.selectedTechnician = [];
            this.frontendErrors.selectedPenaltyType = [];

            if (currentStatus) {
                this.frontendErrors.selectedStatus = [];
                if (this.selectedStatus.value !== undefined && this.selectedStatus.value == 7) {
                    await this.getTechnicians({
                        requestedDate: this.appointmentDate,
                        requestedTimeSlot: this.appointmentTime.slice(0, -2).trim(), // 09:00 time format
                        state: this.appointmentState,
                        preference: this.appointmentPreference,
                        requested_time_interval: this.appointment.requested_time_interval

                    });
                }
            }
        },

      
        async selectedRescheduleDate(currentDate) {

            if (currentDate) {
                this.frontendErrors.selectedRescheduleTime = [];
                this.selectedRescheduleTime = "";
                if (this.selectedStatus.value !== undefined && this.selectedStatus.value == 10) {
                    this.selectedTechnician = "";
                    this.frontendErrors.selectedTechnician = [];
                }
                this.frontendErrors.selectedRescheduleDate = [];

                await this.getTechnicianTimeSlots(currentDate);

            }
        },
        async selectedRescheduleTime(currentTime) {
            if (currentTime) {
                this.frontendErrors.selectedRescheduleTime = [];
                if (this.selectedStatus.value !== undefined && this.selectedStatus.value == 10) {
                    this.selectedTechnician = "";
                    this.frontendErrors.selectedTechnician = [];
                    await this.getTechnicians({
                        requestedDate: this.selectedRescheduleDate,
                        requestedTimeSlot: this.selectedRescheduleTime.value,
                        state: this.appointmentState,
                        preference: this.appointmentPreference,
                        requested_time_interval: this.appointment.requested_time_interval
                    });
                }
            }

        },
        length(currentLength) {
            if (currentLength) {
                this.frontendErrors.length = [];
            }
        },
        haveANote(currentNoteStatus) {

            this.selectedUserType = "";
            if (currentNoteStatus == true) {
                if (this.userAllTypes.length == 1) {
                    this.selectedUserType = this.userAllTypes[0];
                }
            }
            this.noteDescription = "";
            this.frontendErrors.selectedUserType = [];
            this.frontendErrors.noteDescription = [];
        },
        selectedUserType(currentUserTYpe) {
            if (currentUserTYpe) {
                this.frontendErrors.selectedUserType = [];
            }
        },
        noteDescription(currentDescription) {
            if (currentDescription) {
                this.frontendErrors.noteDescription = [];
            }
        },
        selectedTechnician(currentTech) {
            if (currentTech) {
                this.frontendErrors.selectedTechnician = [];
            }
        },
        selectedPenaltyType(currentPenaltyType) {
            if (currentPenaltyType) {
                this.frontendErrors.selectedPenaltyType = [];
            }
        },

        appointmentStatusCancelled: {
            handler: async function (val) {
                const {
                    cancellationRequestedByCustomer,
                    selectedPenaltyType,
                    selectedStatus
                } = val;

                if (selectedStatus.value == 1 && selectedPenaltyType.value !== undefined) {
                    this.updateBtnIsActive = true;
                    const payLoad = {
                        id: this.appointmentId,
                        data: {
                            client_panel: 1,
                            status: this.selectedStatus.value,
                            cancellation_requested_by_customer: cancellationRequestedByCustomer === true ? 1 : 0,
                            cancellation_penalty_type: selectedPenaltyType.value
                        }
                    }
                    await this.appointmentStatusUpdateChargeCalculation(payLoad);

                }
            },
            deep: true
        },
        appointmentStatusCredited: {
            handler: async function (val) {
                const {

                    selectedStatus
                } = val;

                if (selectedStatus.value == 3) {
                    this.updateBtnIsActive = true;
                    const payLoad = {
                        id: this.appointmentId,
                        data: {
                            client_panel: 1,
                            status: selectedStatus.value,

                        }
                    }
                    await this.appointmentStatusUpdateChargeCalculation(payLoad);

                }
            },
            deep: true
        },
        appointmentStatusDelivered: {
            handler: async function (val) {
                const {

                    selectedStatus,
                    length
                } = val;

                if (selectedStatus.value == 4) {
                    if (length.length == 5) {

                        this.updateBtnIsActive = true;
                        const payLoad = {
                            id: this.appointmentId,
                            data: {
                                client_panel: 1,
                                status: selectedStatus.value,
                                length: length,

                            }
                        }
                        await this.appointmentStatusUpdateChargeCalculation(payLoad);

                    } else {
                        this.updateBtnIsActive = false;
                        this.statusPreUpdateChargeResponse = {};
                    }
                }

            },
            deep: true
        },
        appointmentStatusRefund: {
            handler: async function (val) {

                const {
                    selectedStatus
                } = val;

                if (selectedStatus.value == 8) {
                    this.updateBtnIsActive = true;

                    const payLoad = {
                        id: this.appointmentId,
                        data: {
                            client_panel: 1,
                            status: selectedStatus.value,

                        }
                    }
                    await this.appointmentStatusUpdateChargeCalculation(payLoad);

                }
            },
            deep: true
        },
        appointmentStatusReschedule: {
            handler: async function (val) {
                const {
                    selectedStatus,
                    selectedRescheduleDate,
                    selectedRescheduleTime,
                    rescheduleRequestedByCustomer
                } = val;

                if (selectedStatus.value == 9) {

                    if (selectedRescheduleTime.value !== undefined && selectedRescheduleDate) {
                        this.updateBtnIsActive = true;

                        const payLoad = {
                            id: this.appointmentId,
                            data: {
                                client_panel: 1,
                                status: selectedStatus.value,
                                reschedule_date: selectedRescheduleDate,
                                reschedule_time: selectedRescheduleTime.value,
                                reschedule_requested_by_customer: rescheduleRequestedByCustomer === true ? 1 : 0,

                            }
                        }
                        await this.appointmentStatusUpdateChargeCalculation(payLoad);

                    } else {
                        this.updateBtnIsActive = false;
                        this.statusPreUpdateChargeResponse = {};
                    }
                }

            },
            deep: true
        },

        appointmentStatusRescheduleAndReassigned: {
            handler: async function (val) {
                const {
                    selectedStatus,
                    selectedRescheduleDate,
                    selectedRescheduleTime,
                    rescheduleRequestedByCustomer
                } = val;

                if (selectedStatus.value == 10) {

                    if (selectedRescheduleTime.value !== undefined && selectedRescheduleDate) {
                        this.updateBtnIsActive = true;

                        const payLoad = {
                            id: this.appointmentId,
                            data: {
                                client_panel: 1,
                                status: selectedStatus.value,
                                reschedule_date: selectedRescheduleDate,
                                reschedule_time: selectedRescheduleTime.value,
                                reschedule_requested_by_customer: rescheduleRequestedByCustomer === true ? 1 : 0,

                            }
                        }
                        await this.appointmentStatusUpdateChargeCalculation(payLoad);

                    } else {
                        this.updateBtnIsActive = false;
                        this.statusPreUpdateChargeResponse = {};
                    }
                }

            },
            deep: true
        },
        // extraDurationDiscountableAmount(currentExtraDurationDiscountableAmount) {

        //     if (currentExtraDurationDiscountableAmount === '') {
        //         return currentExtraDurationDiscountableAmount;
        //     }
        //     console.log(currentExtraDurationDiscountableAmount, "pre");
        //     let amount = parseFloat(currentExtraDurationDiscountableAmount);

        //     if (isNaN(amount)) {
        //         this.showToastMessage({
        //             type: 'error',
        //             message: 'Pleae enter a valid amount'
        //         });
        //         return currentExtraDurationDiscountableAmount;
        //     }
        //     if (amount < 0) {
        //         this.showToastMessage({
        //             type: 'error',
        //             message: 'Pleae enter a valid amount'
        //         });
        //         return currentExtraDurationDiscountableAmount;
        //     }
        //     if (amount > this.statusPreUpdateChargeResponse.extra_duration_discountable) {
        //         this.showToastMessage({
        //             type: 'error',
        //             message: `Max amount is ${this.statusPreUpdateChargeResponse.extra_duration_discountable}`
        //         });
        //         return currentExtraDurationDiscountableAmount;
        //     }

        // }

    },
    computed: {
        ...mapGetters({
            timeIntervalOptions: 'timeIntervalOptions',
            availableTechnicians: 'appTechnicians/availableTechnicians',
            technicianAvailableTimeSlots: 'appTechnicians/technicianAvailableTimeSlots',
            settingAppointmentReschedulePolicy: 'appSettings/settingAppointmentReschedulePolicy',
            settingAppointmentCreditedPolicy: 'appSettings/settingAppointmentCreditedPolicy',
            settingAppointmentRefundPolicy: 'appSettings/settingAppointmentRefundPolicy',
            // settingAppointmentCancellationPolicy: 'appSettings/settingAppointmentCancellationPolicy',
            // userAllTypes: "appUsers/userAllTypes",
        }),
        showPaymentCollection(){
          let paymentStatus = Boolean(this.appointment?.appointmentPayments?.length ?? 0);
          return (this.selectedStatus.value == 1 && this.cancellationRequestedByCustomer && !paymentStatus) && (this.statusPreUpdateChargeResponse?.details?.interval_hours < 48);
        },
        availableTechniciansWithDistanceAndDuration() {
            let availableTechnicians = [];
            availableTechnicians = (this.$store.getters["appTechnicians/availableTechnicians"] ?.technicians ?? []).map((technician) => ({
                name: `${technician.name} ( ${technician.distance} by ${technician.duration})`,
                id: technician.id,
            }));
            return availableTechnicians;
        },

        allAppointmentStatus() {
            let allStatus = this.$store.getters["appSettings/settingAppointmentStatus"].value;
            if (this.$store.getters["appSettings/settingAppointmentStatus"].value.length > 0) {
                return allStatus.filter((status) => {
                    if (this.appointmentTechnicianId) {
                        return status.value != null && status.value != 0;
                    } else {
                        // if no tech assigned then reschedule and delivered option will not display in front end 
                        return status.value != null && status.value != 9 && status.value != 0 && status.value != 4;
                    }
                });
                // this.appointmentTechnicianId
            } else {
                return [];
            }

        },
        penaltyTypes() {
            return [{
                    name: 'Refund',
                    value: 0
                },
                {
                    name: 'Credited',
                    value: 1
                },
            ]
        },

        isUpdateBtnDisable() {
            if (this.selectedStatus.value != undefined) {
                if ((this.selectedStatus.value == 1 || this.selectedStatus.value == 3 || this.selectedStatus.value == 4 || this.selectedStatus.value == 8 || this.selectedStatus.value == 9 || this.selectedStatus.value == 10)) {
                    if (this.updateBtnIsActive == true) {
                        return false;
                    }

                    return true;

                }
                return false;
            }
            return false;

        },

        // appointment status update charge calculation api call computed properties
        appointmentStatusCancelled() {
            const {
                cancellationRequestedByCustomer,
                selectedStatus,
                selectedPenaltyType
            } = this;
            return {
                cancellationRequestedByCustomer,
                selectedStatus,
                selectedPenaltyType
            }

        },
        appointmentStatusCredited() {
            const {
                selectedStatus,
            } = this;
            return {
                selectedStatus,
            }

        },
        appointmentStatusDelivered() {
            const {
                selectedStatus,
                length
            } = this;
            return {
                selectedStatus,
                length
            }

        },
        appointmentStatusRefund() {
            const {
                selectedStatus,

            } = this;
            return {
                selectedStatus,

            }

        },
        appointmentStatusReschedule() {
            const {
                selectedStatus,
                selectedRescheduleDate,
                selectedRescheduleTime,
                rescheduleRequestedByCustomer

            } = this;
            return {
                selectedStatus,
                selectedRescheduleDate,
                selectedRescheduleTime,
                rescheduleRequestedByCustomer

            }

        },
        appointmentStatusRescheduleAndReassigned() {
            const {
                selectedStatus,
                selectedRescheduleDate,
                selectedRescheduleTime,
                rescheduleRequestedByCustomer

            } = this;
            return {
                selectedStatus,
                selectedRescheduleDate,
                selectedRescheduleTime,
                rescheduleRequestedByCustomer

            }

        },

    },

    methods: {
        ...mapActions({
            getAvailableTechnicians: 'appTechnicians/getAvailableTechnicians',
            putAppointmentStatus: 'appAppointments/putAppointmentStatus',
            postAppointmentSchedule: 'appAppointmentSchedules/postAppointmentSchedule',
            postTechnicianAvailableTimeSlots: 'appTechnicians/postTechnicianAvailableTimeSlots',
            postAppointmentHistory: 'appAppointments/postAppointmentHistory',
            putAppointmentStatusCharge: 'appAppointments/putAppointmentStatusCharge',
            postAppointmentStatusUpdateNotifyEmail: 'appAppointments/postAppointmentStatusUpdateNotifyEmail',
            postCustomerOpinionRequestEmail: 'appCustomerOpinions/postCustomerOpinionRequestEmail',
            postLoyaltyPointSystemUsing: 'apployaltyPoints/postLoyaltyPointSystemUsing',
            postDiscountStoreList: 'appDiscounts/postDiscountStoreList',
            postAppointmentPaymentCollection: 'appAppointmentPaymentCollections/postAppointmentPaymentCollection'

        }),

        async applyExtraDurationDiscountableAmount() {

            let amount = parseFloat(this.extraDurationDiscountableAmount);

            if (isNaN(amount)) {
                this.showToastMessage({
                    type: 'error',
                    message: 'Please enter a valid amount'
                });
                return;
            }

            if (amount < 0) {
                this.showToastMessage({
                    type: 'error',
                    message: 'Please enter a valid amount'
                });
                return;
            }

            if (amount > this.statusPreUpdateChargeResponse.extra_duration_discountable) {
                this.showToastMessage({
                    type: 'error',
                    message: `Max amount is ${this.statusPreUpdateChargeResponse.extra_duration_discountable}`
                });
                return;
            }
            const payLoad = {
                id: this.appointmentId,
                data: {
                    client_panel: 1,
                    status: this.selectedStatus.value,
                    length: this.length,
                    extra_duration_applied_discount_amount: amount * 100

                }
            }

            await this.appointmentStatusUpdateChargeCalculation(payLoad);

        },

        createAppointmentDiscountStoreList(appointmentUserId, appointmentPreference, extraDurationDiscountableAmount) {
            /*
              0:Credited Payment,
              1:Applied on Subtotal,
              2:Applied on Shipping Fee, 
              3:Coupon on Subtotal, 
              4:Coupon on Shipping Fee, 
              5:Parking, 
              6:Online Appointment
              7:Loyalty Discount
              */
            let discountsArray = [{
                "amount": extraDurationDiscountableAmount * 100,
                "type": 1,
            }];

            const dataObj = {
                user_id: appointmentUserId,
                reference: appointmentPreference,
                discounts: discountsArray
            }
            this.postDiscountStoreList(dataObj);
        },
        validateExtraDurationDiscountableAmount(currentExtraDurationDiscountableAmount) {

            if (currentExtraDurationDiscountableAmount === '') {
                return;
            }

            let amount = parseFloat(currentExtraDurationDiscountableAmount);

            if (isNaN(amount)) {
                this.showToastMessage({
                    type: 'error',
                    message: 'Please enter a valid amount'
                });
                return;
            }
            if (amount === 0 || amount < 0) {
                this.showToastMessage({
                    type: 'error',
                    message: 'Please enter a valid amount'
                });
                return;
            }
            if (amount > this.statusPreUpdateChargeResponse.extra_duration_discountable) {
                this.showToastMessage({
                    type: 'error',
                    message: `Max amount is ${this.statusPreUpdateChargeResponse.extra_duration_discountable}`
                });
                return;
            }
            return true;
        },
        getDestinationAddress(address) {

            return `${address?.street?? ''}, ${address?.suburb ?? ''} ${address?.state?.toLowerCase() ?? ''} ${address?.post_code ?? ''}, ${address?.country ?? ''}`

        },
        async getTechnicians(getTechniciansParamData) {

            let time = getTechniciansParamData.requestedTimeSlot.split(':');
            if (time.length > 2) {
                getTechniciansParamData.requestedTimeSlot = `${time[0]}:${time[1]}`
            }
            this.loader(true);

            const data = {
                requested_date: getTechniciansParamData.requestedDate,
                requested_time_slot: getTechniciansParamData.requestedTimeSlot,
                state: getTechniciansParamData.state,
                preference: getTechniciansParamData.preference,
                destination_address: this.getDestinationAddress(this.appointmentServiceAddress),
                requested_time_interval: getTechniciansParamData.requested_time_interval


            }

            const response = await this.getAvailableTechnicians(data);

            if (response.status === 200) {

                this.loader(false);
                if (this.availableTechnicians.status === false) {
                    this.showToastMessage({
                        message: this.availableTechnicians.message,
                        type: "error"
                    });
                }

            } else {
                this.loader(false);

            }

            if (response.message) {
                this.showToastMessage(response);
            }

        },

        async getTechnicianTimeSlots(date) {

            this.loader(true);
            const data = {
                requested_date: date,
                state: this.appointmentState,
                preference: this.appointmentPreference,
                panel: 1,
                requested_time_interval: this.appointment.requested_time_interval
            }
            if (this.appointmentTechnicianId && this.selectedStatus.value == 9) {
                data.technician_id = this.appointmentTechnicianId;
            }
            const response = await this.postTechnicianAvailableTimeSlots(data);

            if (response.message) {
                this.showToastMessage(response);
            }

            if (response.status === 200) {

                this.loader(false);
                if (this.technicianAvailableTimeSlots.status === false) {
                    this.showToastMessage({
                        message: this.technicianAvailableTimeSlots.message,
                        type: "error"
                    });
                }

            } else {
                this.loader(false);

            }

        },

        async updateAppointmentStatusHandler() {
            if (this.selectedStatus.value !== undefined) {

                this.frontendErrors.selectedStatus = [];
                if (this.selectedStatus.value == 9) {

                    // for reschedule status

                    this.frontendErrors.selectedRescheduleTime = [];
                    this.frontendErrors.selectedRescheduleDate = [];

                    if (this.selectedRescheduleDate) {
                        if (this.technicianAvailableTimeSlots.status === false) {
                            this.showToastMessage({
                                message: this.technicianAvailableTimeSlots.message,
                                type: "error"
                            });
                        }
                        if (this.selectedRescheduleDate && this.selectedRescheduleTime) {

                            if (this.haveANote) {
                                let isTrue = this.updateStatusWithNoteValidation();

                                if (isTrue) {
                                    const data = {
                                        id: this.appointmentId,
                                        data: {
                                            status: parseInt(this.selectedStatus.value),
                                            reschedule_date: this.selectedRescheduleDate,
                                            reschedule_time: this.selectedRescheduleTime.value,
                                            appointment_note: this.noteDescription,
                                            user_type: this.selectedUserType.value,
                                            user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                            reschedule_requested_by_customer: this.rescheduleRequestedByCustomer ? 1 : 0,
                                            customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

                                        }

                                    }
                                    await this.updateStatusByApiCall(data);
                                }
                            } else {
                                this.frontendErrors.selectedRescheduleTime = [];
                                this.frontendErrors.selectedRescheduleDate = [];
                                const data = {
                                    id: this.appointmentId,
                                    data: {
                                        status: parseInt(this.selectedStatus.value),
                                        reschedule_date: this.selectedRescheduleDate,
                                        reschedule_time: this.selectedRescheduleTime.value,
                                        user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                        reschedule_requested_by_customer: this.rescheduleRequestedByCustomer ? 1 : 0
                                    }
                                }
                                await this.updateStatusByApiCall(data);
                            }

                        }
                        if ((!this.selectedRescheduleTime) && this.selectedRescheduleDate) {
                            this.frontendErrors.selectedRescheduleTime.push('Please enter time');
                        }

                    }

                    if (!this.selectedRescheduleDate) {
                        this.frontendErrors.selectedRescheduleDate.push('Please enter date');
                    }

                } else if (this.selectedStatus.value == 10) {

                    // for reschedule status

                    this.frontendErrors.selectedRescheduleTime = [];
                    this.frontendErrors.selectedRescheduleDate = [];
                    this.frontendErrors.selectedTechnician = [];

                    if (this.selectedRescheduleDate) {
                        if (this.technicianAvailableTimeSlots.status === false) {
                            this.showToastMessage({
                                message: this.technicianAvailableTimeSlots.message,
                                type: "error"
                            });
                        }
                        if (this.availableTechnicians.status === false) {
                            //  availableTechnicians.technicians !== undefined &&  availableTechnicians.technicians.length > 0
                            this.showToastMessage({
                                message: this.availableTechnicians.message,
                                type: "error"
                            });
                        }
                        if (this.selectedRescheduleDate && this.selectedRescheduleTime && this.selectedTechnician) {

                            if (this.haveANote) {
                                let isTrue = this.updateStatusWithNoteValidation();

                                if (isTrue) {
                                    const data = {
                                        id: this.appointmentId,
                                        data: {
                                            status: parseInt(this.selectedStatus.value),
                                            reschedule_date: this.selectedRescheduleDate,
                                            reschedule_time: this.selectedRescheduleTime.value,
                                            technician_id: this.selectedTechnician.id,
                                            appointment_note: this.noteDescription,
                                            user_type: this.selectedUserType.value,
                                            user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                            reschedule_requested_by_customer: this.rescheduleRequestedByCustomer ? 1 : 0,
                                            customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

                                        }

                                    }
                                    await this.updateStatusByApiCall(data);
                                }
                            } else {
                                this.frontendErrors.selectedRescheduleTime = [];
                                this.frontendErrors.selectedRescheduleDate = [];
                                const data = {
                                    id: this.appointmentId,
                                    data: {
                                        status: parseInt(this.selectedStatus.value),
                                        reschedule_date: this.selectedRescheduleDate,
                                        reschedule_time: this.selectedRescheduleTime.value,
                                        technician_id: this.selectedTechnician.id,
                                        reschedule_requested_by_customer: this.rescheduleRequestedByCustomer ? 1 : 0,
                                        user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                    }
                                }
                                await this.updateStatusByApiCall(data);
                            }

                        }
                        if ((!this.selectedRescheduleTime) && this.selectedRescheduleDate) {
                            this.frontendErrors.selectedRescheduleTime.push('Please enter time');
                        }
                        if ((this.selectedRescheduleTime) && this.selectedRescheduleDate && !this.selectedTechnician) {
                            this.frontendErrors.selectedTechnician.push('Please select a technician');
                        }

                    }

                    if (!this.selectedRescheduleDate) {
                        this.frontendErrors.selectedRescheduleDate.push('Please enter date');
                    }

                } else if (this.selectedStatus.value == 7) {
                    // for reassigned status
                    this.frontendErrors.selectedTechnician = [];
                    if (this.availableTechnicians.status === false) {
                        this.showToastMessage({
                            message: this.availableTechnicians.message,
                            type: "error"
                        });
                    }
                    if (this.selectedTechnician) {

                        if (this.haveANote) {
                            let isTrue = this.updateStatusWithNoteValidation();
                            if (isTrue) {
                                const data = {
                                    id: this.appointmentId,
                                    data: {
                                        status: parseInt(this.selectedStatus.value),
                                        technician_id: this.selectedTechnician.id,
                                        appointment_note: this.noteDescription,
                                        user_type: this.selectedUserType.value,
                                        user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                        customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

                                    }

                                }
                                await this.updateStatusByApiCall(data);
                            }
                        } else {
                            this.frontendErrors.selectedTechnician = [];
                            const data = {
                                id: this.appointmentId,
                                data: {
                                    status: parseInt(this.selectedStatus.value),
                                    technician_id: this.selectedTechnician.id,
                                    user_id: this.$store.getters["appAuthLogin/authUser"].id,

                                }
                            }

                            await this.updateStatusByApiCall(data);
                        }

                    }

                    if (!this.selectedTechnician) {
                        this.frontendErrors.selectedTechnician = [];
                        this.frontendErrors.selectedTechnician.push('Please select a technician');

                    }

                } else if (this.selectedStatus.value == 4) {
                    //for delivery status
                    if (this.length) {
                        if (this.haveANote) {
                            let isTrue = this.updateStatusWithNoteValidation();
                            if (isTrue) {
                                const data = {
                                    id: this.appointmentId,
                                    data: {
                                        status: parseInt(this.selectedStatus.value),
                                        length: this.length,
                                        appointment_note: this.noteDescription,
                                        user_type: this.selectedUserType.value,
                                        user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                        customer_can_view: this.visibleNoteToCustomer ? 1 : 0,
                                    }

                                }
                                if (this.statusPreUpdateChargeResponse ?.extra_duration_discountable) {
                                    if ((this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount) === 0 && (this.extraDurationDiscountableAmount === "")) {
                                        if (data ?.extra_duration_applied_discount_amount) {
                                            delete data.extra_duration_applied_discount_amount;
                                        }

                                        // nothing to do 
                                    } else if ((this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount !== (this.extraDurationDiscountableAmount))) {
                                        this.showToastMessage({
                                            type: 'error',
                                            message: 'The discount amount is not applied yet.'
                                        });
                                        return;
                                    } else {
                                        data.data.extra_duration_applied_discount_amount = (this.extraDurationDiscountableAmount * 100);
                                    }
                                }

                                await this.updateStatusByApiCall(data);
                            }
                        } else {
                            this.frontendErrors.length = [];
                            const data = {
                                id: this.appointmentId,
                                data: {
                                    status: parseInt(this.selectedStatus.value),
                                    length: this.length,
                                    user_id: this.$store.getters["appAuthLogin/authUser"].id,

                                }
                            }
                            if (this.statusPreUpdateChargeResponse ?.extra_duration_discountable) {
                                if ((this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount) === 0 && (this.extraDurationDiscountableAmount === "")) {
                                    if (data ?.extra_duration_applied_discount_amount) {
                                        delete data.extra_duration_applied_discount_amount;
                                    }
                                } else if ((this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount) === 0 && (this.extraDurationDiscountableAmount === 0)) {
                                    if (data ?.extra_duration_applied_discount_amount) {
                                        delete data.extra_duration_applied_discount_amount;
                                    }
                                } else if ((this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount !== (this.extraDurationDiscountableAmount))) {
                                    this.showToastMessage({
                                        type: 'error',
                                        message: 'The discount amount is not applied yet.'
                                    });
                                    return;

                                } else {
                                    data.data.extra_duration_applied_discount_amount = (this.extraDurationDiscountableAmount * 100);
                                }
                            }
                            await this.updateStatusByApiCall(data);
                        }
                    } else {
                        this.frontendErrors.length = [];
                        this.frontendErrors.length.push('Please enter length');
                    }
                } else if (this.selectedStatus.value == 1) {
                    //for Cancelled status
                    if (this.selectedPenaltyType) {
                        if (this.haveANote) {
                            let isTrue = this.updateStatusWithNoteValidation();
                            if (isTrue) {
                                const data = {
                                    id: this.appointmentId,
                                    data: {
                                        status: parseInt(this.selectedStatus.value),
                                        cancellation_penalty_type: this.selectedPenaltyType.value,
                                        appointment_note: this.noteDescription,
                                        user_type: this.selectedUserType.value,
                                        cancellation_requested_by_customer: this.cancellationRequestedByCustomer ? 1 : 0,
                                        user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                        customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

                                    }

                                }
                                await this.updateStatusByApiCall(data);
                            }
                        } else {
                            this.frontendErrors.length = [];
                            const data = {
                                id: this.appointmentId,
                                data: {
                                    status: parseInt(this.selectedStatus.value),
                                    cancellation_penalty_type: this.selectedPenaltyType.value,
                                    user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                    cancellation_requested_by_customer: this.cancellationRequestedByCustomer ? 1 : 0,

                                }
                            }

                            await this.updateStatusByApiCall(data);
                        }
                    } else {
                        this.frontendErrors.selectedPenaltyType = [];
                        this.frontendErrors.selectedPenaltyType.push('Please select a penalty type');
                    }
                } else {
                    if (this.haveANote) {

                        let isTrue = this.updateStatusWithNoteValidation();
                        if (isTrue) {
                            const data = {
                                id: this.appointmentId,
                                data: {
                                    status: parseInt(this.selectedStatus.value),
                                    appointment_note: this.noteDescription,
                                    user_type: this.selectedUserType.value,
                                    user_id: this.$store.getters["appAuthLogin/authUser"].id,
                                    customer_can_view: this.visibleNoteToCustomer ? 1 : 0,

                                }

                            }
                            await this.updateStatusByApiCall(data);
                        }

                    } else {
                        const data = {
                            id: this.appointmentId,
                            data: {
                                status: parseInt(this.selectedStatus.value),
                                user_id: this.$store.getters["appAuthLogin/authUser"].id,

                            }

                        }
                        await this.updateStatusByApiCall(data);
                    }

                }
            } else {
                this.frontendErrors.selectedStatus = [];
                this.frontendErrors.selectedStatus.push("Please select status")
            }

        },

        async updateStatusByApiCall(data) {
            this.loader(true);
            const response = await this.putAppointmentStatus(data);

            if (response.status === 200) {

                if (response.message.status === true) {
                    this.createAppointmentHistory();


                    this.appointmentStatusUpdateEmailNotify();
                    if (this.selectedStatus.value == 4) {
                        this.loyaltyPointSystemUsingHandler(this.appointmentReference);
                        if (this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount && (this.statusPreUpdateChargeResponse ?.details ?.extra_duration_applied_discount !== 0)) {
                            this.createAppointmentDiscountStoreList(this.appointmentUserId, this.appointmentReference, this.extraDurationDiscountableAmount);
                        }

                        this.customerOpinionRequestEmailNotify();

                    }
                    if (this.selectedStatus.value === 4 || this.selectedStatus.value === 7) {
                        if (this.appointment.appointmentSchedules.length > 0) {
                            if (this.appointment.appointmentSchedules[this.appointment.appointmentSchedules.length - 1] ?.type === "Started") {
                                this.appointmentScheduleCreate({
                                    appointment_id: this.appointment.id,
                                    time: new Date().toLocaleString("en-US", {
                                        hour12: false,
                                        hour: "2-digit",
                                        minute: '2-digit',
                                        timeZone: "Australia/Sydney"
                                    }),
                                    type: 1,
                                    visit_day: this.appointment.appointmentSchedules[this.appointment.appointmentSchedules.length - 1].visit_day
                                });
                            }

                        }

                    }
                    // appointment payment collection
                    if (this.showPaymentCollection && this.paymentNeedCollectedStatus){
                        await this.postAppointmentPaymentCollection({
                          appointment_id: this.appointment.id,
                          created_by: this.$store.getters["appAuthLogin/authUser"].id,
                          status: 1,
                          details: null
                        })
                    }
                    this.loader(false);
                    this.resetData();
                    this.showToastMessage({
                        message: `${response.message.message}`,
                        type: 'success'
                    });
                    // for delivery status this email will send to customer for their feedback

                    document.querySelector('[data-target="#AppointmentStatusUpdateModal"]').click();

                    this.$emit('getAppointmentList', this.appointmentParams);

                } else {
                    this.loader(false);
                    this.showToastMessage({
                        message: `${response.message.message}`,
                        type: 'error'
                    });
                }

            } else {
                if (response.message) {
                    this.showToastMessage(response);
                }
                this.loader(false);

            }
        },
        customerOpinionRequestEmailNotify() {
            const data = {
                id: this.appointmentReference,
                params: {
                    notify_customer: 1,
                    notify_internal_user: 1
                }

            };
            this.postCustomerOpinionRequestEmail(data);
        },
        getTimeFormat(date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            hours = hours ? hours : 12; // the hour '0' should be "12"
            hours = hours < 10 ? '0' + hours : hours;
            // appending zero in the start if hours less than 10
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return hours + ':' + minutes;
        },

        // postLoyaltyPointSystemUsing
        appointmentStatusUpdateEmailNotify() {
            const data = {
                id: this.appointmentId,
                params: {
                    notify_customer: this.notifyToCustomer ? 1 : 0,
                    notify_technician: 1,
                    notify_internal_user: 1
                }

            };

            this.postAppointmentStatusUpdateNotifyEmail(data);
        },
        appointmentScheduleCreate(data) {
            this.postAppointmentSchedule(data);
        },
        loyaltyPointSystemUsingHandler(appointmentReference) {
            const data = {
                type: 0,
                reference: appointmentReference,
            };
            this.postLoyaltyPointSystemUsing(data);
        },
        async appointmentStatusUpdateChargeCalculation(data) {
            this.loader(true);
            const response = await this.putAppointmentStatusCharge(data);

            if (response.status === 200 || response.status === 201) {
                this.statusPreUpdateChargeResponse = response.data;

                this.loader(false);

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            this.loader(false);
        },

        createAppointmentHistory() {
            const data = {
                user_id: this.$store.getters["appAuthLogin/authUser"].id,
                appointment_id: this.appointmentId,
                technician_id: (this.selectedStatus.value == 7 || this.selectedStatus.value == 10) ? this.selectedTechnician.id : this.appointmentTechnicianId,
                panel: 1,
                status: this.selectedStatus.value,
            };

            this.postAppointmentHistory(data);

        },
        updateStatusWithNoteValidation() {

            this.frontendErrors.selectedUserType = [];
            this.frontendErrors.noteDescription = [];

            if (!this.selectedUserType) {
                this.frontendErrors.selectedUserType = [];
                this.frontendErrors.selectedUserType.push("Please select a user type");
            }
            if ((!this.noteDescription) && this.selectedUserType) {
                this.frontendErrors.noteDescription = [];
                this.frontendErrors.noteDescription.push("Please enter note description");
            }

            if (this.selectedUserType && this.noteDescription) {
                return true;
            }

        },
        resetData() {
          
            this.isExtraDurationDiscountableAmountIsApplied = false;
            this.extraDurationDiscountableAmount = "";
            this.updateBtnIsActive = false;

            this.selectedRescheduleDate = '';
            this.selectedRescheduleTime = '';
            this.selectedPenaltyType = '';
            this.length = '';

            this.selectedStatus = '';
            this.selectedTechnician = '';

            this.selectedUserType = "";
            this.haveANote = false;
            this.rescheduleRequestedByCustomer = false;
            this.cancellationRequestedByCustomer = false;
            this.notifyToCustomer = false;
            this.visibleNoteToCustomer = false;
            this.noteDescription = "";

            this.frontendErrors.selectedStatus = [];
            this.frontendErrors.selectedRescheduleDate = [];
            this.frontendErrors.selectedRescheduleTime = [];
            this.frontendErrors.length = [];
            this.frontendErrors.selectedTechnician = [];
            this.frontendErrors.selectedUserType = [];
            this.frontendErrors.noteDescription = [];
            this.frontendErrors.selectedPenaltyType = [];

        }
    }
}

// this.$store.getters["appAuthLogin/authUser"].id
</script>

<style>
</style>
